import { Button, Grid, Typography } from "@material-ui/core";
import useAuth from "hooks/useAuth.js";
import useCompanies from "hooks/useCompanies";
import useImages from "hooks/useImages";
import useMediaUpload from "hooks/useMediaUpload";
import React from "react";
import ModalImage from "react-modal-image";

const ImageCard = ({ 
    companyId, 
    relaction, 
    description, 
    update,
    dark,
    uploadFile,
    image,
    setImage
}) => {
  
    const attachmentFile = React.useRef(null)

    const handleAttachmentFile = async event => {
        const file = event.target.files[0]
        if (!file) return

        const { filename, originalname, blob } = await uploadFile(file)

        const newFile = {
            fileName: relaction,
            name: originalname,
            stored: filename,
            url: blob
        }

        const updated = await update({id: companyId, [relaction]: newFile})
        setImage(prev => ({...prev, [relaction]: updated[relaction]}))
    }

    const handleDeleteFile = async event => {
        const updated = await update({id: companyId, [relaction]: null})
        setImage(prev => ({...prev, [relaction]: updated[relaction]}))
    }

    return (
        <Grid container spacing={2}>
            <input 
                type="file" 
                style={{ display: 'none' }} 
                ref={attachmentFile} 
                onChange={handleAttachmentFile} 
            />

            <Grid item xs={12}>
                <Typography align="center" variant="h6" style={{ borderTop: '1px solid', marginTop: 8 }}>{description}</Typography> 
            </Grid>

            <Grid item xs={6} >
                <Button 
                    onClick={() => attachmentFile.current?.click()} 
                    color="primary" 
                    variant="outlined"
                    fullWidth
                >
                    Selecionar
                </Button>
            </Grid>

            <Grid item xs={6} >
                <Button 
                    onClick={handleDeleteFile} 
                    color="secondary" 
                    variant="outlined"
                    fullWidth
                >
                    Remover
                </Button>
            </Grid>

            <Grid item xs={12} style={{ backgroundColor: dark ? "#111b21" : null }} >
                <ModalImage
                    smallSrcSet={image} 
                    medium={image} 
                    large={image} 
                    alt="image"
                />
            </Grid>
        </Grid>
    )
}

const CompanyImages = () => {
    
    const { user } = useAuth();
    const { update } = useCompanies()
    const { uploadFile } = useMediaUpload()
    const { logo, logoDark, whatsappBackgroundLight, whatsappBackgroundDark, setImages } = useImages()
    
    const companyId = user.companyId

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} >
                <ImageCard
                    companyId={companyId}
                    update={update}
                    uploadFile={uploadFile}
                    relaction="logo"
                    description="Logo"
                    image={logo}
                    setImage={setImages}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3} >
                <ImageCard
                    companyId={companyId}
                    update={update}
                    uploadFile={uploadFile}
                    relaction="logoDark"
                    description="Logo (Dark)"
                    dark={true}
                    image={logoDark}
                    setImage={setImages}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3} >
                <ImageCard
                    companyId={companyId}
                    update={update}
                    uploadFile={uploadFile}
                    relaction="whatsappBackgroundLight"
                    description="Background"
                    image={whatsappBackgroundLight}
                    setImage={setImages}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3} >
                <ImageCard
                    companyId={companyId}
                    update={update}
                    uploadFile={uploadFile}
                    dark={true}
                    relaction="whatsappBackgroundDark"
                    description="Background (Dark)"
                    image={whatsappBackgroundDark}
                    setImage={setImages}
                />
            </Grid>
        </Grid>
    )
}

export default CompanyImages