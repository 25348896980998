import { uniqBy } from 'lodash'
import useStyles from '../useStyles'
import clsx from 'clsx'

export default function Reaction({ currentMessage }) {
    const classes = useStyles()

    const { reactionsMsg } = currentMessage

    if (!reactionsMsg || !reactionsMsg.length) return null

    return (
        <div className={clsx([classes.reaction, 'reaction'])}>
            {uniqBy(reactionsMsg, 'body').map((react, index) => {
                return <span key={index}>{react.body}</span>
            })}
        </div>
    )
}
