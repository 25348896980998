import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import CustomNodeBase from '../../components/CustomNodeBase'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import MarkdownWrapper from 'components/MarkdownWrapper'
import { useRef } from 'react'
import { Box } from '@material-ui/core'

function SendInvoiceNode(myNode) {
    const { data, ...props } = myNode
    const refCustomNode = useRef(null)

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                node={myNode}
                ref={refCustomNode}
                name="Último boleto"
                icon={MonetizationOnIcon}
            >
                {Boolean(data.message) ? (
                    <MarkdownWrapper>
                        {data.message}
                    </MarkdownWrapper>
                ) : (
                    <Box onClick={() => refCustomNode.current?.open()}>
                        Clique para definir uma mensagem!
                    </Box>
                )}
            </CustomNodeBase>
            <CustomStartHandle position={Position.Bottom} nodeId={props.id} />
        </div>
    )
}

export default SendInvoiceNode
