import React, { useEffect, useState } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Checkbox, ListItemText } from '@material-ui/core'
import { Can } from 'components/Can'
import useCan from 'hooks/useCan'
import { cloneDeep } from 'lodash'

const TicketsQueueSelect = ({
    userQueues,
    selectedQueueIds = [],
    onChange,
}) => {
    const [checkedAll, setCheckedAll] = useState(false)
    const { can } = useCan()

    useEffect(() => {
        const queuesIds = cloneDeep(userQueues)?.map(q => q.id) || []
        if (can('ticket:see:without:department')) queuesIds.push(null)

        const difference = queuesIds.filter(
            queues => !selectedQueueIds.includes(queues),
        )

        if (difference.length) return setCheckedAll(false)

        return setCheckedAll(true)
    }, [userQueues, selectedQueueIds, can])

    const handleChange = e => {
        const values = e.target.value
        if (values.includes('Select all')) return

        onChange(values.filter(r => r !== 'Select all'))
    }

    const selectAll = () => {
        if (checkedAll) {
            onChange([])
        } else {
            const toAdd = userQueues.map(q => q.id)

            if (can('ticket:see:without:department')) toAdd.push(null)
            onChange(toAdd)
        }
    }

    const selectNull = () => {
        const currentSelected = cloneDeep(selectedQueueIds)
        const nullSelect = currentSelected.indexOf(null)

        if (nullSelect === -1) {
            onChange([null, ...currentSelected])
        } else {
            currentSelected.splice(nullSelect, 1)
            onChange(currentSelected)
        }
    }

    return (
        <div style={{ width: 120, marginTop: -4 }}>
            <FormControl fullWidth margin="dense">
                <Select
                    multiple
                    displayEmpty
                    variant="outlined"
                    value={selectedQueueIds}
                    onChange={handleChange}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                    renderValue={() => 'Setores'}>
                    <MenuItem dense value="Select all" onClick={selectAll}>
                        <Checkbox
                            size="small"
                            color="primary"
                            checked={checkedAll}
                        />
                        <ListItemText
                            primary={
                                checkedAll
                                    ? 'Desmarcar tudo'
                                    : 'Marcar tudo'
                            }
                        />
                    </MenuItem>

                    <Can perform="ticket:see:without:department">
                        <MenuItem dense value={null} onClick={selectNull}>
                            <Checkbox
                                size="small"
                                color="primary"
                                checked={selectedQueueIds.indexOf(null) > -1}
                            />
                            <ListItemText primary="Sem Setor" />
                        </MenuItem>
                    </Can>

                    {userQueues?.length > 0 &&
                        userQueues.map(queue => (
                            <MenuItem dense key={queue.id} value={queue.id}>
                                <Checkbox
                                    style={{
                                        color: queue.color,
                                    }}
                                    size="small"
                                    color="primary"
                                    checked={
                                        selectedQueueIds.indexOf(queue.id) > -1
                                    }
                                />
                                <ListItemText primary={queue.name} />
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default TicketsQueueSelect
