import React, { useState, useEffect } from 'react'
import Validator from 'helpers/Validator'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    DialogActions,
    CircularProgress,
    TextField,
    Switch,
    FormControlLabel,
    Grid,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    IconButton,
    Popover,
    Tooltip,
} from '@material-ui/core'

import api from '../../services/api'
import toastError from '../../errors/toastError'
import QueueSelect from '../QueueSelect'
import FormikFlowSelect from '../FlowSelect/FormikFlowSelect'
import { ExpandMore, Info } from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'
import { Picker } from 'emoji-mart'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: 'relative',
    },

    emojiBox: {
        zIndex: 10,
        position: 'absolute',
        borderTop: '1px solid #e8e8e8',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

const ratingMessage = '*Digite de 1 a 5 para qualificar nosso atendimento:*'

const ratingRanking = {
    5: { icon: '🤩', rate: 5, label: 'Muito Satisfeito' },
    4: { icon: '😀', rate: 4, label: 'Satisfeito' },
    3: { icon: '😐', rate: 3, label: 'Indiferente' },
    2: { icon: '😤', rate: 2, label: 'Insatisfeito' },
    1: { icon: '😡', rate: 1, label: 'Muito Insatisfeito' },
}

const SessionSchema = Validator.object().shape({
    name: Validator.string().min(2).max(50).required(),
})

const EmojiOptions = ({ value, onChange }) => {
    const theme = useTheme()

    const [anchor, setAnchor] = React.useState(null)

    const showEmoji = !!anchor
    const emojiIcon = value

    return (
        <>
            <IconButton
                aria-label="emojiPicker"
                component="span"
                style={{ color: 'unset' }}
                onClick={e => setAnchor(e.currentTarget)}>
                {emojiIcon}
            </IconButton>
            <Popover
                id="emojiPicker"
                open={showEmoji}
                anchorEl={anchor}
                onClose={e => setAnchor(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Picker
                    perLine={16}
                    showPreview={false}
                    showSkinTones={false}
                    onSelect={emj => onChange?.(emj.native)}
                    theme={theme.mode}
                />
            </Popover>
        </>
    )
}

const RankingField = ({ name, value, onChange, ...rest }) => {
    return (
        <Grid container alignItems="center">
            <Grid item xs={1}>
                <Grid container alignItems="center">
                    <Grid item xs={2}>
                        <Typography variant="subtitle2">
                            {value?.rate}
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <EmojiOptions
                            value={value?.icon}
                            onChange={newValue =>
                                onChange({
                                    target: {
                                        name,
                                        value: { ...value, icon: newValue },
                                    },
                                })
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={11}>
                <TextField
                    fullWidth
                    value={value?.label}
                    onChange={event =>
                        onChange({
                            target: {
                                name,
                                value: { ...value, label: event.target.value },
                            },
                        })
                    }
                />
            </Grid>
        </Grid>
    )
}

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
    const classes = useStyles()

    const initialState = {
        name: '',
        greetingMessage: '',
        completionMessage: '',
        outOfHoursMessage: '',
        ratingMessage,
        ratingRanking,
        isDefault: false,
        token: '',
        expiresInactiveMessage: '',
        expiresTicket: 0,
    }
    const [whatsApp, setWhatsApp] = useState(initialState)
    const [selectedTransferQueueId, setSelectedTransferQueueId] = useState(null)

    useEffect(() => {
        const fetchSession = async () => {
            if (!whatsAppId) return

            try {
                const { data } = await api.get(
                    `whatsapp/${whatsAppId}?session=0`,
                )
                setWhatsApp(data)

                setSelectedTransferQueueId(data.transferQueueId)
            } catch (err) {
                toastError(err)
            }
        }
        fetchSession()
    }, [whatsAppId])

    const handleSaveWhatsApp = async values => {
        const whatsappData = {
            ...values,
            transferQueueId: selectedTransferQueueId,
        }
        delete whatsappData['session']

        try {
            if (whatsAppId) {
                await api.put(`/whatsapp/${whatsAppId}`, whatsappData)
            } else {
                await api.post('/whatsapp', whatsappData)
            }
            toast.success('WhatsApp salvo com sucesso.')
            handleClose()
        } catch (err) {
            toastError(err)
        }
    }

    const handleClose = () => {
        onClose()
        setWhatsApp(initialState)
        setSelectedTransferQueueId(null)
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                scroll="paper">
                <DialogTitle>
                    {whatsAppId ? 'Editar WhatsApp' : 'Adicionar WhatsApp'}
                </DialogTitle>
                <Formik
                    initialValues={whatsApp}
                    enableReinitialize={true}
                    validationSchema={SessionSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveWhatsApp(values)
                            actions.setSubmitting(false)
                        }, 400)
                    }}>
                    {({
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <Grid spacing={2} container>
                                        <Grid item>
                                            <Field
                                                as={TextField}
                                                label={'Nome'}
                                                autoFocus
                                                name="name"
                                                error={
                                                    touched.name &&
                                                    Boolean(errors.name)
                                                }
                                                helperText={
                                                    touched.name && errors.name
                                                }
                                                variant="outlined"
                                                margin="dense"
                                                className={classes.textField}
                                            />
                                        </Grid>
                                        <Grid style={{ paddingTop: 15 }} item>
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        as={Switch}
                                                        color="primary"
                                                        name="isDefault"
                                                        checked={
                                                            values.isDefault
                                                        }
                                                    />
                                                }
                                                label={'Padrão'}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div>
                                    <h3>Recepção do atendimento</h3>
                                    <div>
                                        <Field
                                            as={FormikFlowSelect}
                                            fullWidth
                                            name="flowId"
                                        />
                                    </div>
                                    {!values.flowId && (
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={'Mensagem de saudação'}
                                                type="greetingMessage"
                                                multiline
                                                rows={4}
                                                fullWidth
                                                name="greetingMessage"
                                                error={
                                                    touched.greetingMessage &&
                                                    Boolean(
                                                        errors.greetingMessage,
                                                    )
                                                }
                                                helperText={
                                                    touched.greetingMessage &&
                                                    errors.greetingMessage
                                                }
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <Field
                                        as={TextField}
                                        label={'Mensagem de fora de expediente'}
                                        type="outOfHoursMessage"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        name="outOfHoursMessage"
                                        error={
                                            touched.outOfHoursMessage &&
                                            Boolean(errors.outOfHoursMessage)
                                        }
                                        helperText={
                                            touched.outOfHoursMessage &&
                                            errors.outOfHoursMessage
                                        }
                                        variant="outlined"
                                        margin="dense"
                                    />
                                </div>
                                <div>
                                    <Field
                                        as={TextField}
                                        label={'Mensagem de conclusão'}
                                        type="completionMessage"
                                        multiline
                                        rows={4}
                                        fullWidth
                                        name="completionMessage"
                                        error={
                                            touched.completionMessage &&
                                            Boolean(errors.completionMessage)
                                        }
                                        helperText={
                                            touched.completionMessage &&
                                            errors.completionMessage
                                        }
                                        variant="outlined"
                                        margin="dense"
                                    />
                                </div>
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 4,
                                        }}>
                                        <h3>Avaliação do atendimento</h3>
                                        <Tooltip
                                            title={`Deixe o campo "Mensagem de avaliação" vazio para desativar a avaliação. `}
                                            placement="right"
                                            arrow>
                                            <Info fontSize="small" />
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Field
                                            as={TextField}
                                            label={'Mensagem de avaliação'}
                                            type="ratingMessage"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            name="ratingMessage"
                                            error={
                                                touched.ratingMessage &&
                                                Boolean(errors.ratingMessage)
                                            }
                                            helperText={
                                                touched.ratingMessage &&
                                                errors.ratingMessage
                                            }
                                            variant="outlined"
                                            margin="dense"
                                        />
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                id="rating-ranking-header">
                                                <Typography variant="subtitle1">
                                                    Classificação da avaliação
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid
                                                    container
                                                    style={{ gap: '1rem' }}>
                                                    {Object.keys(ratingRanking)
                                                        .reverse()
                                                        .map(key => (
                                                            <Field
                                                                key={key}
                                                                as={
                                                                    RankingField
                                                                }
                                                                name={`ratingRanking.${key}`}
                                                            />
                                                        ))}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                                <div>
                                    <h3>Redirecionamento de setor</h3>
                                    <p>
                                        Selecione um setor para os contatos que
                                        não possuem setor serem redirecionados
                                    </p>
                                    <Grid container spacing={2}>
                                        <Grid item sm={6}>
                                            <Field
                                                fullWidth
                                                type="number"
                                                as={TextField}
                                                label="Transferir após x (minutos)"
                                                name="timeToTransfer"
                                                error={
                                                    touched.timeToTransfer &&
                                                    Boolean(
                                                        errors.timeToTransfer,
                                                    )
                                                }
                                                helperText={
                                                    touched.timeToTransfer &&
                                                    errors.timeToTransfer
                                                }
                                                variant="outlined"
                                                margin="dense"
                                                className={classes.textField}
                                                onChange={e =>
                                                    setFieldValue(
                                                        'timeToTransfer',
                                                        e.target.value !== ''
                                                            ? parseInt(
                                                                  e.target
                                                                      .value,
                                                              )
                                                            : null,
                                                    )
                                                }
                                                InputLabelProps={{
                                                    shrink: !!values.timeToTransfer,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item sm={6}>
                                            <QueueSelect
                                                value={selectedTransferQueueId}
                                                onChange={selectedId => {
                                                    setSelectedTransferQueueId(
                                                        selectedId,
                                                    )
                                                }}
                                                multiple={false}
                                                title={'Transferência de Setor'}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid spacing={2} container>
                                        {/* ENCERRAR CHATS ABERTOS APÓS X HORAS */}
                                        <Grid xs={12} md={12} item>
                                            <Field
                                                as={TextField}
                                                type="number"
                                                label={
                                                    'Encerrar chats abertos após x minutos'
                                                }
                                                fullWidth
                                                name="expiresTicket"
                                                variant="outlined"
                                                margin="dense"
                                                error={
                                                    touched.expiresTicket &&
                                                    Boolean(
                                                        errors.expiresTicket,
                                                    )
                                                }
                                                onChange={e =>
                                                    setFieldValue(
                                                        'expiresTicket',
                                                        e.target.value !== ''
                                                            ? parseInt(
                                                                  e.target
                                                                      .value,
                                                              )
                                                            : null,
                                                    )
                                                }
                                                helperText={
                                                    touched.expiresTicket &&
                                                    errors.expiresTicket
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* MENSAGEM POR INATIVIDADE*/}
                                    <div>
                                        <Field
                                            as={TextField}
                                            label={
                                                'Mensagem de encerramento por inatividade'
                                            }
                                            multiline
                                            rows={4}
                                            fullWidth
                                            name="expiresInactiveMessage"
                                            error={
                                                touched.expiresInactiveMessage &&
                                                Boolean(
                                                    errors.expiresInactiveMessage,
                                                )
                                            }
                                            helperText={
                                                touched.expiresInactiveMessage &&
                                                errors.expiresInactiveMessage
                                            }
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h3>API</h3>
                                    <Field
                                        as={TextField}
                                        label={'Token'}
                                        type="token"
                                        fullWidth
                                        name="token"
                                        variant="outlined"
                                        margin="dense"
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined">
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}>
                                    {whatsAppId ? 'Salvar' : 'Adicionar'}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}

export default React.memo(WhatsAppModal)
