import { Grid, IconButton, TextField } from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete'
import { Field } from "formik"

const AskFormItems = ({ onRemove, item, ...props }) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name={`items[${item}].description`}
                    label="Descrição"
                    error={props.touched?.items?.[item]?.description && props.errors?.items?.[item]?.description}
                    helperText={props.touched?.items?.[item]?.description && props.errors?.items?.[item]?.description}
                    {...props.getFieldProps(`items[${item}].description`)}
                />
            </Grid>

            <Grid item xs={5}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name={`items[${item}].save`}
                    label="Salvar"
                    error={props.touched?.items?.[item]?.save && props.errors?.items?.[item]?.save}
                    helperText={props.touched?.items?.[item]?.save && props.errors?.items?.[item]?.save}
                    {...props.getFieldProps(`items[${item}].save`)}
                />
            </Grid>

            <Grid item xs={1}>
                <IconButton onClick={onRemove}>
                    <DeleteIcon />
                </IconButton>
            </Grid>

            {/* {props.touched.items?.[item]?.message &&
                props.errors.items?.length && (
                    <FormHelperText>
                        {props.errors.items[item]?.message}
                    </FormHelperText>
                )} */}
        </Grid>
    )
}

export default AskFormItems
