import React, { useEffect, useState } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import toastError from '../../errors/toastError'
import api from '../../services/api'

const RoleSelect = ({
    value,
    onChange,
    multiple = false,
    label = "Cargo",
    ...props
}) => {
    const [roles, setRoles] = useState([])

    useEffect(() => {
        fetchRoles()
    }, [])

    const fetchRoles = async () => {
        try {
            const { data } = await api.get('/role')
            setRoles(data.data)
        } catch (err) {
            toastError(err)
        }
    }

    const handleChange = e => {
        onChange(e.target.value)
    }

    return (
        <FormControl fullWidth margin="dense" variant="outlined" {...props}>
            <InputLabel shrink={value ? true : false}>{label}</InputLabel>
            <Select
                label={label}
                multiple={multiple}
                labelWidth={60}
                value={value || ''}
                onChange={handleChange}
                renderValue={selected => {
                    const rolesToShow = roles.filter(
                        role =>
                            role.id === selected ||
                            (Array.isArray(selected) &&
                                selected.includes(role.id)),
                    )
                    return rolesToShow.map(role => role.name).join(', ')
                }}>
                {roles.map(role => (
                    <MenuItem key={role.id} value={role.id}>
                        {role.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default RoleSelect
