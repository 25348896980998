import React, { useState, useContext } from 'react'

import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import { AuthContext } from '../../context/Auth/AuthContext'
import logo from '../../assets/logo.png'
import logoWhite from '../../assets/logo-white.png'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100vw',
        height: '100dvh',
        background: 'linear-gradient(to right, #284359 , #284359 , #000000)', //cor de fundo
        //backgroundImage: "url(https://#)",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    paper: {
        backgroundColor: theme.palette.login, //DARK MODE PLW DESIGN//
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '55px 30px',
        borderRadius: '12.5px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    powered: {
        color: 'white',
    },
}))

const Login = () => {
    const classes = useStyles()
    const theme = useTheme()
    const { companyId } = useParams()

    const [user, setUser] = useState({ email: '', password: '', companyId })

    const { handleLogin } = useContext(AuthContext)

    const handleChangeInput = e => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const handlSubmit = e => {
        e.preventDefault()
        handleLogin(user)
    }

    return (
        <div className={classes.root}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <div>
                        <center>
                            <img
                                style={{ margin: '0 auto', width: '70%' }}
                                src={theme.mode === 'light' ? logo : logoWhite}
                                alt="Logologin"
                            />
                        </center>
                    </div>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={handlSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="companyId"
                            label="Código da empresa"
                            name="companyId"
                            value={user.companyId}
                            onChange={handleChangeInput}
                            autoComplete="companyId"
                            disabled={![undefined, null].includes(companyId)}
                            autoFocus={[undefined, null].includes(companyId)}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={'Email'}
                            name="email"
                            value={user.email}
                            onChange={handleChangeInput}
                            autoComplete="email"
                            autoFocus={![undefined, null].includes(companyId)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={'Senha'}
                            type="password"
                            id="password"
                            value={user.password}
                            onChange={handleChangeInput}
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            {'Entrar'}
                        </Button>
                    </form>
                </div>
            </Container>
        </div>
    )
}

export default Login
