import React, { useCallback, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from '../../services/api'
import { uniq } from 'lodash'
import { Chip, makeStyles } from '@material-ui/core'

const useStyle = makeStyles(theme => ({
    tagChip: {
        color: '#FFF',
        marginRight: 1,
        fontWeight: 600,
        borderRadius: 3,
        fontSize: '0.8em',
        whiteSpace: 'nowrap',
    },
}))

export default function TagAutocomplete({
    value,
    onChange,
    multiple = true,
    limitShow = 1,
    label = 'Campos Customizados',
    ...props
}) {
    const classes = useStyle()

    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const [labelOptions, setLabelOptions] = useState([])
    const loading = open && options.length === 0

    const validProps = {
        className: props.className,
        style: props.style,
        disableClearable: props.disableClearable,
    }

    useEffect(() => {
        if (!value || (multiple && !value.length)) return

        const getLabel = async tags => {
            if (!tags) return

            return (await api.get(`/tags`, { filters: { idIn: tags } })).data
                .data
        }

        if (multiple)
            new Promise(async resolve => {
                const labels = await getLabel(value)
                setLabelOptions(labels)
                resolve(true)
            })
        else
            getLabel(value).then(label => {
                setLabelOptions(label)
            })
    }, [value, multiple])

    useEffect(() => {
        let active = true

        if (!loading) return undefined

        api.get('/tags').then(r => {
            if (active) {
                setOptions(r.data.data)
            }
        })

        return () => (active = false)
    }, [loading])

    useEffect(() => {
        if (!open) setOptions([])
    }, [open])

    const handleChange = useCallback(
        (e, value) => {
            setLabelOptions(multiple ? uniq(value, 'id') : value)
            if (!value) return onChange(null)

            if (multiple) return onChange(uniq(value, 'id').map(v => v.id))

            onChange(value.id)
        },
        [multiple, onChange],
    )

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name || option}
            options={options}
            loading={loading}
            onChange={handleChange}
            multiple={multiple}
            value={labelOptions}
            {...validProps}
            limitTags={limitShow}
            disableCloseOnSelect={multiple}
            renderTags={(value, getTagProps) => {
                const numTags = value.length
                const limitTags = limitShow

                return (
                    <>
                        {value.slice(0, limitTags).map((option, index) => (
                            <Chip
                                variant="outlined"
                                style={{ background: option.color || '#eee' }}
                                classes={{ root: classes.tagChip }}
                                label={option.name}
                                {...getTagProps({ index })}
                                size="small"
                            />
                        ))}

                        {numTags > limitTags && ` +${numTags - limitTags}`}
                    </>
                )
            }}
            renderOption={option => (
                <Chip
                    variant="outlined"
                    style={{ background: option.color || '#eee' }}
                    classes={{ root: classes.tagChip }}
                    label={option.name}
                    size="small"
                />
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}
