import { Chip, makeStyles, Paper, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect, useRef, useState } from 'react'
import { isArray, isString } from 'lodash'
import toastError from '../../errors/toastError'
import api from '../../services/api'

const useStyles = makeStyles(theme => ({
    autocomplete: {
        maxHeight: '16dvh',
        overflow: 'auto',
    },
}))

export function TagsContainer({ ticket }) {
    const classes = useStyles()

    const [tags, setTags] = useState([])
    const [selecteds, setSelecteds] = useState([])
    const isMounted = useRef(true)

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (isMounted.current) {
            loadTags().then(() => {
                if (Array.isArray(ticket.tags)) {
                    setSelecteds(ticket.tags)
                } else {
                    setSelecteds([])
                }
            })
        }
    }, [ticket])

    const createTag = async data => {
        try {
            const { data: responseData } = await api.post(`/tags`, data)
            return responseData
        } catch (err) {
            toastError(err)
        }
    }

    const loadTags = async () => {
        try {
            const { data } = await api.get(`/tags`, {
                filters: { countTags: 1 },
            })
            setTags(data.data)
        } catch (err) {
            toastError(err)
        }
    }

    const syncTags = async data => {
        try {
            const { data: responseData } = await api.post(`/tags/sync`, data)
            return responseData
        } catch (err) {
            toastError(err)
        }
    }

    const onChange = async (value, reason) => {
        let optionsChanged = []
        if (reason === 'create-option') {
            if (isArray(value)) {
                for (let item of value) {
                    if (isString(item)) {
                        const newTag = await createTag({ name: item })
                        if (newTag) optionsChanged.push(newTag)
                    } else {
                        optionsChanged.push(item)
                    }
                }
            }
            await loadTags()
        } else {
            optionsChanged = value
        }
        setSelecteds(optionsChanged)
        await syncTags({ ticketId: ticket.id, tags: optionsChanged })
    }

    return (
        <Paper style={{ padding: 6 }}>
            <Autocomplete
                multiple
                freeSolo
                size="small"
                options={tags}
                value={selecteds}
                className={classes.autocomplete}
                onChange={(e, v, r) => onChange(v, r)}
                getOptionLabel={option => option.name}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            style={{
                                background: option.color || '#eee',
                                color: '#FFF',
                                marginRight: 1,
                                fontWeight: 600,
                                borderRadius: 3,
                                fontSize: '0.8em',
                                whiteSpace: 'nowrap',
                            }}
                            label={option.name}
                            {...getTagProps({ index })}
                            size="small"
                        />
                    ))
                }
                renderOption={option => (
                    <Chip
                        variant="outlined"
                        style={{
                            background: option.color || '#eee',
                            color: '#FFF',
                            marginRight: 1,
                            fontWeight: 600,
                            borderRadius: 3,
                            fontSize: '0.8em',
                            whiteSpace: 'nowrap',
                        }}
                        label={option.name}
                        size="small"
                    />
                )}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Tags"
                    />
                )}
                PaperComponent={({ children }) => (
                    <Paper style={{ width: '100%', marginLeft: 6 }}>
                        {children}
                    </Paper>
                )}
            />
        </Paper>
    )
}
