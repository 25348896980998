import React, { useContext, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Badge } from '@material-ui/core'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import LinearScaleIcon from '@material-ui/icons/LinearScale'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd'
import { WhatsAppsContext } from '../context/WhatsApp/WhatsAppsContext'
import { Can } from '../components/Can'

function ListItemLink(props) {
    const { icon, primary, to, className } = props

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to],
    )

    return (
        <li>
            <ListItem button dense component={renderLink} className={className}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    )
}

const MainListItems = props => {
    const { drawerClose } = props
    const { whatsApps } = useContext(WhatsAppsContext)
    const [connectionWarning, setConnectionWarning] = useState(false)
    // const [showIntegrations, setShowIntegrations] = useState(false)
    // const [showExternalApi, setShowExternalApi] = useState(false)

    // const { getPlanCompany } = usePlans()
    // useEffect(() => {
    //     async function fetchData() {
    //         const companyId = user.companyId
    //         const planConfigs = await getPlanCompany(companyId)

    //         setShowIntegrations(planConfigs.plan.useIntegrations)
    //         setShowExternalApi(planConfigs.plan.useExternalApi)
    //     }
    //     fetchData()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter(whats => {
                    return (
                        whats.status === 'qrcode' ||
                        whats.status === 'PAIRING' ||
                        whats.status === 'DISCONNECTED' ||
                        whats.status === 'TIMEOUT' ||
                        whats.status === 'OPENING'
                    )
                })
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true)
                } else {
                    setConnectionWarning(false)
                }
            }
        }, 2000)
        return () => clearTimeout(delayDebounceFn)
    }, [whatsApps])

    return (
        <div onClick={drawerClose}>
            <Can
                perform={[
                    'dashboard:page:general',
                    'dashboard:page:tickets',
                    'dashboard:page:metrics',
                ]}>
                <ListItemLink
                    to="/"
                    primary="Dashboard"
                    icon={<DashboardOutlinedIcon />}
                />
            </Can>

            <ListItemLink
                to="/tickets"
                primary="Atendimentos"
                icon={<WhatsAppIcon />}
            />

            <Can perform="quickMessage:page">
                <ListItemLink
                    to="/quick-messages"
                    primary="Respostas Rápidas"
                    icon={<FlashOnIcon />}
                />
            </Can>

            <Can perform="contact:page">
                <ListItemLink
                    to="/contacts"
                    primary="Contatos"
                    icon={<ContactPhoneOutlinedIcon />}
                />
            </Can>

            <Can perform="tag:page">
                <ListItemLink
                    to="/tags"
                    primary="Tags"
                    icon={<LocalOfferIcon />}
                />
            </Can>

            <Can perform="customField:page">
                <ListItemLink
                    to="/custom-field"
                    primary="Campos Customizados"
                    icon={<LibraryAddIcon />}
                />
            </Can>

            <Can perform="connection:page">
                <ListItemLink
                    to="/connections"
                    primary="Conexões"
                    icon={
                        <Badge
                            badgeContent={connectionWarning ? '!' : 0}
                            color="error">
                            <SyncAltIcon />
                        </Badge>
                    }
                />
            </Can>

            <Can perform="queue:page">
                <ListItemLink
                    to="/queues"
                    primary="Setores"
                    icon={<AccountTreeOutlinedIcon />}
                />
            </Can>

            <Can perform="chatFlow:page">
                <ListItemLink
                    to="/chat-flows"
                    primary="ChatBot"
                    icon={<LinearScaleIcon />}
                />
            </Can>

            <Can perform="user:page">
                <ListItemLink
                    to="/users"
                    primary="Usuários"
                    icon={<PersonOutlineOutlinedIcon />}
                />
            </Can>

            <Can perform="role:page">
                <ListItemLink
                    to="/roles"
                    primary="Cargos"
                    icon={<PeopleAltOutlinedIcon />}
                />
            </Can>

            <Can perform="setting:page">
                <ListItemLink
                    to="/settings"
                    primary="Configurações"
                    icon={<SettingsOutlinedIcon />}
                />
            </Can>

            {/* <Can perform="customField:page">
                <ListItemLink
                    to="/queue-integration"
                    primary={'Integrações'}
                    icon={<DeviceHubOutlined />}
                />
            </Can> */}
           
            {/* <Can perform="customField:page">
                <ListItemLink
                    to="/messages-api"
                    primary={'API de Mensagens'}
                    icon={<CodeRoundedIcon />}
                />
            </Can> */}
           
        </div>
    )
}

export default MainListItems

