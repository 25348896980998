import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import CustomNodeBase from '../../components/CustomNodeBase'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import { useRef } from 'react'
import { Box } from '@material-ui/core'

function NotifyNode(myNode) {
    const { data, ...props } = myNode
    const refCustomNode = useRef(null)

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                node={myNode}
                name="Notificação"
                ref={refCustomNode}
                icon={NotificationsActiveIcon}
            >
                {Boolean(data.number) ? 
                <div>{data.number}</div> : 
                <Box onClick={() => refCustomNode.current?.open()}>
                    Clique para configurar!
                </Box>}
                <div>{data.notification}</div>
            </CustomNodeBase>
            <CustomStartHandle position={Position.Bottom} nodeId={props.id} />
        </div>
    )
}

export default NotifyNode
