import useAuth from "hooks/useAuth.js";
import useCompanies from "hooks/useCompanies";
import React, { createContext } from "react";

import logoDefault from "../../assets/logo.png"
import logoDefaultDark from "../../assets/logo-white.png"
import whatsBackground from '../../assets/wa-background.png'
import whatsBackgroundDark from '../../assets/wa-background-dark.png'

const ImagesContext = createContext();

const ImagesProvider = ({ children }) => {
    
    const { user } = useAuth()
    const { find } = useCompanies()

    const companyId = user.companyId

    const [images, setImages] = React.useState({})
    const [noCache, setNoCache] = React.useState(Date.now())

    React.useEffect(() => {
        if (!companyId) return

        const asyncFetch = async () => {
            const company = await find(companyId, { 
                include: {
                    logo: '*,@url',
                    logoDark: '*,@url',
                    whatsappBackgroundLight: '*,@url',
                    whatsappBackgroundDark: '*,@url'
                }
            })
            setImages({
                logo: company?.logo,
                logoDark: company?.logoDark,
                whatsappBackgroundLight: company?.whatsappBackgroundLight,
                whatsappBackgroundDark: company?.whatsappBackgroundDark
            })  
        }
        asyncFetch()
        // eslint-disable-next-line
    }, [companyId])

    React.useEffect(() => {
        if (!Object.keys(images).length) return

        setNoCache(Date.now())
    }, [images])

    const logo = images.logo?.url ? `${images.logo.url}?nocache=${noCache}` : logoDefault
    const logoDark = images.logoDark?.url ? `${images.logoDark.url}?nocache=${noCache}` : logoDefaultDark
    const whatsappBackgroundLight = images.whatsappBackgroundLight?.url ? `${images.whatsappBackgroundLight.url}?nocache=${noCache}` : whatsBackground
    const whatsappBackgroundDark = images.whatsappBackgroundDark?.url ? `${images.whatsappBackgroundDark.url}?nocache=${noCache}` : whatsBackgroundDark

	return (
		<ImagesContext.Provider
			value={{
                images, 
                setImages,
                logo,
                logoDark,
                whatsappBackgroundLight,
                whatsappBackgroundDark,
            }}
		>
			{children}
		</ImagesContext.Provider>
	);
};

export { ImagesContext, ImagesProvider };
