
import React from 'react'

import { isSameDay, parseISO, format } from 'date-fns'
import useStyles from '../useStyles'

export default function DailyTimestamp({ currentMessage, previousMessage, nextMessage }) {
    const classes = useStyles()

    if (previousMessage && !nextMessage)
        return null

    if (previousMessage) {
        let messageDay = parseISO(currentMessage.createdAt)
        let previousMessageDay = parseISO(previousMessage.createdAt)
    
        if (isSameDay(messageDay, previousMessageDay))
            return null
    }

    return (
        <span
            className={classes.dailyTimestamp}
            key={`timestamp-${currentMessage.id}`}>
            <div className={classes.dailyTimestampText}>
                {format(parseISO(currentMessage.createdAt), 'dd/MM/yyyy')}
            </div>
        </span>
    )
}