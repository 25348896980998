import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import CustomNodeBase from '../../components/CustomNodeBase'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import { useRef } from 'react'

function CreateLeadNode(myNode) {
    const { data, ...props } = myNode
    const refCustomNode = useRef(null)

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                node={myNode}
                ref={refCustomNode}
                name="Criar Lead"
                icon={EmojiPeopleIcon}>
                {!Boolean(data.map) ? 'Configure o mapeamento' : 'Cria lead'}
            </CustomNodeBase>
            <CustomStartHandle position={Position.Bottom} nodeId={props.id} />
        </div>
    )
}

export default CreateLeadNode
