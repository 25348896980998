import { useState } from 'react'
import { Button, Collapse, Drawer, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { types, subTypes } from './types.js'

const useStyles = makeStyles(theme => ({
    chatOptions: {
        ...theme.scrollbarStylesThin,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },

    chatOption: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        '& > button': {
            width: '100%',
            '& > span': {
                display: 'flex',
                flexDirection: 'column',
                fontSize: '0.7rem',
            },
        },
    },

    subType: {
        '& > button': {
            background: '#efefef',
        },
    },
    subTypeOpen: {
        '& > button': {
            background: '#dfdfdf',
        },
    },
}))

const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
}

const RenderItems = ({ subtype, onAddNode }) => {
    const classes = useStyles()
    const toShow = types.filter(
        t => (!subtype && !t.subType) || t.subType === subtype,
    )

    return toShow.map((option, i) => (
        <div
            key={i}
            className={classes.chatOption}
            onDragStart={event => onDragStart(event, option.type)}
            draggable>
            <Button onClick={() => onAddNode(option.type)}>
                {option.icon} {option.label}
            </Button>
        </div>
    ))
}

const RenderSubType = ({ subtype, onAddNode }) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    return (
        <div
            className={clsx([
                classes.chatOption,
                classes.subType,
                { [classes.subTypeOpen]: open },
            ])}>
            <Button onClick={() => setOpen(!open)}>
                {subtype.icon}
                {subtype.label}
            </Button>

            <Collapse in={open}>
                <RenderItems subtype={subtype.type} onAddNode={onAddNode} />
            </Collapse>
        </div>
    )
}

function ChatOptions({ reactFlowInstance, onAddNode, ...data }) {
    const classes = useStyles()

    return (
        <>
            <Drawer
                variant="permanent"
                PaperProps={{
                    style: {
                        position: 'unset',
                        justifyContent: 'space-between',
                    },
                }}
                BackdropProps={{ style: { position: 'absolute' } }}>
                <div className={classes.chatOptions}>
                    <RenderItems onAddNode={onAddNode} />

                    {subTypes.map(item => (
                        <RenderSubType subtype={item} onAddNode={onAddNode} />
                    ))}
                </div>
            </Drawer>
        </>
    )
}

export default ChatOptions
