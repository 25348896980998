


export default function Divider({ currentMessage, previousMessage, nextMessage }) {

    if (!previousMessage || !nextMessage || currentMessage.fromMe === previousMessage.fromMe)
        return null

    return (
        <span key={`divider-${currentMessage.id}`} style={{ marginTop: 8 }} />
    )
}