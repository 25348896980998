import { AuthContext } from 'context/Auth/AuthContext'
import { useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'

const useCan = () => {
    const { user } = useContext(AuthContext)
    const history = useHistory()

    const can = useCallback(
        permissions => {
            if (!user.role) return false

            if (user.super || user.role.type === 'admin') return true

            if (!Array.isArray(permissions)) permissions = [permissions]

            const userPermissions = user.role.permissions.map(
                perm => perm.permission,
            )
            for (const permission of permissions) {
                if (userPermissions.includes(permission)) return true
            }

            return false
        },
        [user],
    )

    const canOrReturn = useCallback((permissions, returnTo) => {
        if (can(permissions)) return true

        if (typeof returnTo == 'function') returnTo()

        if (typeof returnTo == 'string') history.push(returnTo)

        return false
    }, [history, can])

    return {
        can,
        canOrReturn,
    }
}

export default useCan
