import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import ModalImage from "react-modal-image";

const useStyles = makeStyles(theme => ({
	messageMedia: {
		objectFit: "cover",
		width: 250,
		height: 200,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},
    sticker: {
        width: 128,
        height: 128
    }
}));

const ModalImageCors = ({ isSticker, imageUrl }) => {
	const classes = useStyles();

	return (
		<ModalImage
			className={clsx([
                classes.messageMedia,
                {
                    sticker: isSticker,
                    [classes.sticker]: isSticker,
                }
            ])}
			smallSrcSet={imageUrl}
			medium={imageUrl}
			large={imageUrl}
			alt="image"
		/>
	);
};

export default ModalImageCors;
