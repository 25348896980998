
import { blue } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    message: {
        marginTop: 2,
        minWidth: 100,
        maxWidth: 600,
        height: 'auto',
        display: 'block',
        color: '#303030',
        position: 'relative',
        whiteSpace: 'pre-wrap',
        padding: 8,
        boxShadow: '0 1px 1px #b3b3b3',

        '&:hover [class*="actionsButton"]': {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
        },

        '&:has([class*="reaction"])': {
            marginBottom: 24,
        },

        '&:has([class*="sticker"])': {
            backgroundColor: 'unset',
            boxShadow: 'unset',

        },

        '&:has([class*="sticker"], [class*="left"]) div.contact': {
            backgroundColor: '#ffffff',
            padding: '4px 8px',
            position: 'relative',
            borderRadius: 8,
            marginBottom: 4,
            left: -8,
        },

        '&:not(:has([class*="body"]))': {
            paddingBottom: 8,
        }
    },
    
    left: {
        alignSelf: 'flex-start',
        marginRight: 20,
        backgroundColor: '#ffffff',
        borderRadius: '0 8px 8px 8px',

        '& [class*="side-bar"]': {
            flex: 'none',
            width: '4px',
            backgroundColor: '#35cd96',
        },
    },
    
    right: {
        alignSelf: 'flex-end',
        marginLeft: 20,
        backgroundColor: '#dcf8c6',
        borderRadius: '8px 8px 0 8px',

        '& [class*="reaction"]': {
            right: 8
        },

        '& [class*="side-bar"]': {
            flex: 'none',
            width: '4px',
            backgroundColor: '#6bcbef',
        },

        '& [class*="quoted"]': {
            backgroundColor: '#cfe9ba',
        },
    },

    focused: {
        boxShadow: '0 4px 4px #b3b3b3',
        transform: 'scale(1.1)',
        border: '1px solid rgba(0, 0, 0, .5)',
    },

    actionsButton: {
        display: 'none',
        position: 'relative',
        color: '#999',
        zIndex: 1,
        backgroundColor: 'inherit',
        opacity: '90%',
        '&:hover, &.Mui-focusVisible': { backgroundColor: 'inherit' },
    },

    information: {
        position: 'absolute',
        right: 6,
        bottom: 0,
        display: 'flex',
        fontSize: '.7rem',
        color: '#999',
        gap: 4,
    },

    reaction: {
        position: 'absolute',
        bottom: -20,

        borderRadius: 16,
        boxShadow: '0 2px 2px #b3b3b3',
        border: '1px solid rgba(0, 0, 0, .1)',
        padding: 2,
        backgroundColor: '#fff'
    },

    text: {
        paddingRight: 32,
        display: 'flex',
        gap: 4,
    },

    body: {
        paddingBottom: 4,
        display: 'flex',
        gap: 4,
    },

    quoted: {
        overflow: 'hidden',
        backgroundColor: '#f0f0f0',
        borderRadius: '7.5px',
        display: 'flex',
        marginBottom: 6,
        marginLeft: -2,
        marginRight: -2,
        marginTop: -2,

        '& .container': {
            padding: 8,
        }
    },

    contact: {
        display: 'flex',
        color: '#6bcbef',
        fontWeight: 500,
    },

    edited: {
        overflowWrap: 'anywhere',
    },

    deleted: {
        fontStyle: 'italic',
        color: 'rgba(0, 0, 0, 0.36)',
        overflowWrap: 'anywhere',
    },




    dailyTimestamp: {
        alignItems: 'center',
        textAlign: 'center',
        alignSelf: 'center',
        width: '110px',
        backgroundColor: '#e1f3fb',
        margin: '10px',
        borderRadius: '10px',
        boxShadow: '0 1px 1px #b3b3b3',
    },

    dailyTimestampText: {
        color: '#808888',
        padding: 8,
        alignSelf: 'center',
        marginLeft: '0px',
    },

    messageCenter: {
        textAlign: 'center',
        marginTop: 5,
    },

    messageContactName: {
        display: 'flex',
        color: '#6bcbef',
        fontWeight: 500,
    },

    messageCall: {
        gap: 4,
        display: 'flex',
        justifyContent: 'center'
    },

    ackIcons: {
        fontSize: 18,
        verticalAlign: 'middle',
        marginLeft: 4,
    },

    ackDoneAllIcon: {
        color: blue[500],
        fontSize: 18,
        verticalAlign: 'middle',
        marginLeft: 4,
    },

}))

export default useStyles