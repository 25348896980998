import { useEffect, useState } from 'react'
import General from './General'
import { Tab, Tabs } from '@material-ui/core'
import TabPanel from 'components/TabPanel'
import Tickets from './Tickets'
import Metrics from './Metricts'
import useCan from 'hooks/useCan'
import { Can } from 'components/Can'

const Dashboard = () => {
    const [tab, setTab] = useState('general')
    const { can, canOrReturn } = useCan()

    useEffect(() => {
        if (!can('dashboard:page:general')) {
            if (!can('dashboard:page:tickets')) setTab('metrics')
            else setTab('tickets')
        }
    }, [can])

    if (
        !canOrReturn(
            [
                'dashboard:page:general',
                'dashboard:page:tickets',
                'dashboard:page:metrics',
            ],
            '/tickets',
        )
    )
        return null

    return (
        <div>
            <Tabs
                value={tab}
                onChange={(e, newValue) => setTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="off">
                {can('dashboard:page:general') && (
                    <Tab label="Geral" value="general" />
                )}
                {can('dashboard:page:tickets') && (
                    <Tab label="Atendimentos" value="tickets" />
                )}
                {can('dashboard:page:metrics') && (
                    <Tab label="Métricas" value="metrics" />
                )}
            </Tabs>
            <TabPanel value={tab} name="general">
                <Can perform="dashboard:page:general">
                    <General />
                </Can>
            </TabPanel>
            <TabPanel value={tab} name="tickets">
                <Can perform="dashboard:page:tickets">
                    <Tickets />
                </Can>
            </TabPanel>
            <TabPanel value={tab} name="metrics">
                <Can perform="dashboard:page:metrics">
                    <Metrics />
                </Can>
            </TabPanel>
        </div>
    )
}

export default Dashboard
