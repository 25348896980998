import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import styles from '../../general.module.scss'
import { useEffect, useRef, useState } from 'react'
import api from 'services/api'
import CustomNodeBase from '../../components/CustomNodeBase'
import MarkdownWrapper from 'components/MarkdownWrapper'
import { Box, makeStyles } from '@material-ui/core'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'

const methods = {
    add: 'Adicionar',
    set: 'Definir',
    remove: 'Remover',
}

const useStyles = makeStyles(theme => ({
    noTag: {
        backgroundColor: theme.palette.error.light,
        paddingInline: 2,
        borderRadius: 3,
        color: 'white',
    },
    tagsContainer: {
        display: 'flex',
        maxWidth: '100%',
        overflow: 'hidden',
        flexWrap: 'wrap',
        gap: 2,
    },
}))

function TagNode(myNode) {
    const { data, ...props } = myNode
    const classes = useStyles()
    const [tags, setTags] = useState()

    const refCustomNode = useRef(null)

    useEffect(() => {
        if (data.tags?.length)
            api.get(`/tags`, { filters: { idIn: data.tags } }).then(r => {
                setTags(r.data.data)
            })
        else setTags([])
    }, [data.tags])

    const renderTag = tag => {
        return (
            <span
                key={tag.id}
                style={{
                    background: tag.color || '#eee',
                    color: '#FFF',
                    marginRight: 1,
                    paddingInline: 3,
                    paddingBlock: 2,
                    fontWeight: 600,
                    borderRadius: 3,
                    fontSize: '0.8em',
                    whiteSpace: 'nowrap',
                }}>
                {tag.name}
            </span>
        )
    }

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase ref={refCustomNode} node={myNode} name="Tag" icon={LocalOfferIcon}>
                {!data?.method ? (
                    <Box onClick={() => refCustomNode.current?.open()}>
                        Clique para configurar!
                    </Box>
                ) : (
                    <>
                        <div className={styles.blockList}>
                            <span className={styles.blockTitle}>
                                {methods[data.method]} Tags:
                            </span>
                            {!!tags?.length && (
                                <div
                                    className={[
                                        styles.blockContent,
                                        classes.tagsContainer,
                                    ].join(' ')}>
                                    {tags?.map(t => renderTag(t))}
                                </div>
                            )}
                            {!tags?.length && (
                                <div className={classes.noTag}>
                                    Nenhuma Tag selecionada!
                                </div>
                            )}
                        </div>

                        {data.message && (
                            <div className={styles.blockList}>
                                <span className={styles.blockTitle}>
                                    Mensagem:
                                </span>
                                <div className={styles.blockContent}>
                                    <MarkdownWrapper>
                                        {data.message}
                                    </MarkdownWrapper>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </CustomNodeBase>
            <CustomStartHandle position={Position.Bottom} nodeId={props.id} />
        </div>
    )
}

export default TagNode
