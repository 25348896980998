import { Grid, MenuItem, Select, TextField } from '@material-ui/core'
import { Field } from 'formik'
import FormikTagAutocomplete from 'components/TagAutocomplete/FormikTagAutocomplete'
import MessageWithPicker from 'pages/ChatFlow/components/MessageWithPicker'
import { Alert } from '@material-ui/lab'

const TagForm = props => {
    if (!props.values.method)
        props.setFieldValue('method', 'add')


    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Grid item sm={4}>
                <Select
                    variant="outlined"
                    defaultValue="add"
                    {...props.getFieldProps('method')}
                    fullWidth
                    onChange={e =>
                        props.setFieldValue('method', e.target.value)
                    }>
                    <MenuItem value="add">Adicionar</MenuItem>
                    <MenuItem value="set">Definir</MenuItem>
                    <MenuItem value="remove">Remover</MenuItem>
                </Select>
            </Grid>

            <Grid item sm={8}>
                <Field
                    as={FormikTagAutocomplete}
                    name="tags"
                    label="Tags"
                    fullWidth
                    variant="outlined"
                    {...props.getFieldProps(`tags`)}
                />
            </Grid>

            <Alert style={{ width: '100%' }} severity="info" icon={false}>
                <ul>
                    <li>
                        <b>Opção Adicionar:</b> Adiciona as tags selecionadas ao
                        ticket;
                    </li>
                    <li>
                        <b>Opção Definir:</b> Remove todas as tags previamente
                        definidas e preenche com as tags selecionadas;
                    </li>
                    <li>
                        <b>Opção Remover:</b> Remove as tags selecionadas, caso
                        o ticket as tenha.
                    </li>
                </ul>
            </Alert>

            <hr style={{ width: '100%' }} />

            <Grid item sm={12}>
                <MessageWithPicker
                    name="message"
                    label="Mensagem"
                    {...props.getFieldProps(`message`)}
                />
            </Grid>
        </Grid>
    )
}

export default TagForm
