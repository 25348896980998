import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BackdropLoading from 'components/BackdropLoading'
import api from 'services/api'
import Ticket from 'components/Ticket'
import { useTheme } from '@material-ui/styles'
import toastError from 'errors/toastError'
import { Backdrop, makeStyles, Typography } from '@material-ui/core'
import ConfirmationModal from 'components/ConfirmationModal'

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

const OpenTicket = (...props) => {
    const classes = useStyles()
    const theme = useTheme()
    const params = useParams()
    const [loadingPage, setLoadingPage] = useState(true)
    const [ticketId, setTicketId] = useState()
    const [cannotAccess, setCannotAccess] = useState()
    const [anotherUser, setAnotherUser] = useState()
    const isWithAnotherUser = Boolean(anotherUser?.id)

    const assumeTicket = useCallback(
        assume => {
            api.post(`tickets/talk`, {
                number: params.number,
                assume
            })
                .then(r => {
                    setAnotherUser(r.data.user)
                    setTicketId(r.data.uuid)
                    setLoadingPage(false)
                })
                .catch(err => {
                    toastError(err)
                    setCannotAccess(true)
                    setLoadingPage(false)
                })
        },
        [params.number],
    )

    useEffect(() => {
        assumeTicket()
    }, [params, assumeTicket])

    if (cannotAccess)
        return (
            <Backdrop className={classes.backdrop} open={true}>
                <Typography>Este contato já está em atendimento ou você não pode acessá-lo, entre em contato com o seu supervisor!</Typography>
            </Backdrop>
        )

    if (loadingPage)
        return (
            <BackdropLoading text="Buscando Ticket. Aguarde alguns instantes!" />
        )

    return (
        <div style={{ height: `calc(100% - ${theme.appBarHeight})` }}>
            <ConfirmationModal
                title="Assumir atendimento"
                open={isWithAnotherUser}
                onClose={() => setAnotherUser(null)}
                onConfirm={() => assumeTicket(true)}>
                Este contato está em atendimento pelo usuário {anotherUser?.name}
                . Você deseja assumir o atendimento?
            </ConfirmationModal>
            <Ticket ticketId={ticketId} ignoreEscape noActions />
        </div>
    )
}

export default OpenTicket
