import React, { useState, useEffect } from 'react'

import Validator from 'helpers/Validator'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
    TextField,
    Grid,
    Paper,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

import api from '../../services/api'
import toastError from '../../errors/toastError'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 4,
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    btnLeft: {
        display: 'flex',
        marginRight: 'auto',
        marginLeft: 12,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}))

const DialogflowSchema = Validator.object().shape({
    name: Validator.string().min(2).max(50).required(),
})

const QueueIntegration = ({ open, onClose, integrationId }) => {
    const classes = useStyles()

    const initialState = {
        type: 'typebot',
        name: '',
        projectName: '',
        jsonContent: '',
        language: '',
        urlN8N: '',
        typebotDelayMessage: 1000,
        typebotExpires: 1,
        typebotKeywordFinish: '',
        typebotKeywordRestart: '',
        typebotRestartMessage: '',
        typebotSlug: '',
        typebotUnknownMessage: '',
    }

    const [integration, setIntegration] = useState(initialState)

    useEffect(() => {
        ;(async () => {
            if (!integrationId) return
            try {
                const { data } = await api.get(
                    `/queueIntegration/${integrationId}`,
                )
                setIntegration(prevState => {
                    return { ...prevState, ...data }
                })
            } catch (err) {
                toastError(err)
            }
        })()

        return () => {
            setIntegration({
                type: 'dialogflow',
                name: '',
                projectName: '',
                jsonContent: '',
                language: '',
                urlN8N: '',
                typebotDelayMessage: 1000,
            })
        }
    }, [integrationId, open])

    const handleClose = () => {
        onClose()
        setIntegration(initialState)
    }

    const handleTestSession = async (event, values) => {
        try {
            const { projectName, jsonContent, language } = values

            await api.post(`/queueIntegration/testSession`, {
                projectName,
                jsonContent,
                language,
            })

            toast.success('Integração testada com sucesso!')
        } catch (err) {
            toastError(err)
        }
    }

    const handleSaveDialogflow = async values => {
        try {
            if (
                values.type === 'n8n' ||
                values.type === 'webhook' ||
                values.type === 'typebot'
            )
                values.projectName = values.name
            if (integrationId) {
                await api.put(`/queueIntegration/${integrationId}`, values)
                toast.success('Integração editada com sucesso.')
            } else {
                await api.post('/queueIntegration', values)
                toast.success('Integração adicionada com sucesso.')
            }
            handleClose()
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                scroll="paper">
                <DialogTitle>
                    {integrationId ? 'Editar projeto' : 'Adicionar projeto'}
                </DialogTitle>
                <Formik
                    initialValues={integration}
                    enableReinitialize={true}
                    validationSchema={DialogflowSchema}
                    onSubmit={(values, actions, event) => {
                        setTimeout(() => {
                            handleSaveDialogflow(values)
                            actions.setSubmitting(false)
                        }, 400)
                    }}>
                    {({ touched, errors, isSubmitting, values }) => (
                        <Form>
                            <Paper
                                square
                                className={classes.mainPaper}
                                elevation={1}>
                                <DialogContent dividers>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={6} xl={6}>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                                margin="dense"
                                                fullWidth>
                                                <InputLabel id="type-selection-input-label">
                                                    Tipo
                                                </InputLabel>

                                                <Field
                                                    as={Select}
                                                    label="Tipo"
                                                    name="type"
                                                    labelId="type-selection-label"
                                                    error={
                                                        touched.type &&
                                                        Boolean(errors.type)
                                                    }
                                                    helpertext={
                                                        touched.type &&
                                                        errors.type
                                                    }
                                                    id="type"
                                                    required>
                                                    <MenuItem value="dialogflow">
                                                        DialogFlow
                                                    </MenuItem>
                                                    <MenuItem value="n8n">
                                                        N8N
                                                    </MenuItem>
                                                    <MenuItem value="webhook">
                                                        WebHooks
                                                    </MenuItem>
                                                    <MenuItem value="typebot">
                                                        Typebot
                                                    </MenuItem>
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        {values.type === 'dialogflow' && (
                                            <>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    xl={6}>
                                                    <Field
                                                        as={TextField}
                                                        label="Nome"
                                                        autoFocus
                                                        name="name"
                                                        fullWidth
                                                        error={
                                                            touched.name &&
                                                            Boolean(errors.name)
                                                        }
                                                        helpertext={
                                                            touched.name &&
                                                            errors.name
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        className={
                                                            classes.textField
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    xl={6}>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={
                                                            classes.formControl
                                                        }
                                                        margin="dense"
                                                        fullWidth>
                                                        <InputLabel id="language-selection-input-label">
                                                            Linguagem
                                                        </InputLabel>

                                                        <Field
                                                            as={Select}
                                                            label="Linguagem"
                                                            name="language"
                                                            labelId="language-selection-label"
                                                            fullWidth
                                                            error={
                                                                touched.language &&
                                                                Boolean(
                                                                    errors.language,
                                                                )
                                                            }
                                                            helpertext={
                                                                touched.language &&
                                                                errors.language
                                                            }
                                                            id="language-selection"
                                                            required>
                                                            <MenuItem value="pt-BR">
                                                                Portugues
                                                            </MenuItem>
                                                            <MenuItem value="en">
                                                                Inglês
                                                            </MenuItem>
                                                            <MenuItem value="es">
                                                                Español
                                                            </MenuItem>
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    xl={6}>
                                                    <Field
                                                        as={TextField}
                                                        label="Nome do Projeto"
                                                        name="projectName"
                                                        error={
                                                            touched.projectName &&
                                                            Boolean(
                                                                errors.projectName,
                                                            )
                                                        }
                                                        helpertext={
                                                            touched.projectName &&
                                                            errors.projectName
                                                        }
                                                        fullWidth
                                                        variant="outlined"
                                                        margin="dense"
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    xl={12}>
                                                    <Field
                                                        as={TextField}
                                                        label="JsonContent"
                                                        type="jsonContent"
                                                        multiline
                                                        maxRows={5}
                                                        minRows={5}
                                                        fullWidth
                                                        name="jsonContent"
                                                        error={
                                                            touched.jsonContent &&
                                                            Boolean(
                                                                errors.jsonContent,
                                                            )
                                                        }
                                                        helpertext={
                                                            touched.jsonContent &&
                                                            errors.jsonContent
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                    />
                                                </Grid>
                                            </>
                                        )}

                                        {(values.type === 'n8n' ||
                                            values.type === 'webhook') && (
                                            <>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    xl={6}>
                                                    <Field
                                                        as={TextField}
                                                        label="Nome"
                                                        autoFocus
                                                        required
                                                        name="name"
                                                        error={
                                                            touched.name &&
                                                            Boolean(errors.name)
                                                        }
                                                        helpertext={
                                                            touched.name &&
                                                            errors.name
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        className={
                                                            classes.textField
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    xl={12}>
                                                    <Field
                                                        as={TextField}
                                                        label="URL"
                                                        name="urlN8N"
                                                        error={
                                                            touched.urlN8N &&
                                                            Boolean(
                                                                errors.urlN8N,
                                                            )
                                                        }
                                                        helpertext={
                                                            touched.urlN8N &&
                                                            errors.urlN8N
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        required
                                                        fullWidth
                                                        className={
                                                            classes.textField
                                                        }
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        {values.type === 'typebot' && (
                                            <>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    xl={6}>
                                                    <Field
                                                        as={TextField}
                                                        label="Nome"
                                                        autoFocus
                                                        name="name"
                                                        error={
                                                            touched.name &&
                                                            Boolean(errors.name)
                                                        }
                                                        helpertext={
                                                            touched.name &&
                                                            errors.name
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        required
                                                        fullWidth
                                                        className={
                                                            classes.textField
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    xl={12}>
                                                    <Field
                                                        as={TextField}
                                                        label="URL"
                                                        name="urlN8N"
                                                        error={
                                                            touched.urlN8N &&
                                                            Boolean(
                                                                errors.urlN8N,
                                                            )
                                                        }
                                                        helpertext={
                                                            touched.urlN8N &&
                                                            errors.urlN8N
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        required
                                                        fullWidth
                                                        className={
                                                            classes.textField
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    xl={6}>
                                                    <Field
                                                        as={TextField}
                                                        label="Typebot - Slug"
                                                        name="typebotSlug"
                                                        error={
                                                            touched.typebotSlug &&
                                                            Boolean(
                                                                errors.typebotSlug,
                                                            )
                                                        }
                                                        helpertext={
                                                            touched.typebotSlug &&
                                                            errors.typebotSlug
                                                        }
                                                        required
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        className={
                                                            classes.textField
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    xl={6}>
                                                    <Field
                                                        as={TextField}
                                                        label="Tempo em minutos para expirar uma conversa"
                                                        name="typebotExpires"
                                                        error={
                                                            touched.typebotExpires &&
                                                            Boolean(
                                                                errors.typebotExpires,
                                                            )
                                                        }
                                                        helpertext={
                                                            touched.typebotExpires &&
                                                            errors.typebotExpires
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        className={
                                                            classes.textField
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    xl={6}>
                                                    <Field
                                                        as={TextField}
                                                        label="Intervalo (ms) entre mensagens"
                                                        name="typebotDelayMessage"
                                                        error={
                                                            touched.typebotDelayMessage &&
                                                            Boolean(
                                                                errors.typebotDelayMessage,
                                                            )
                                                        }
                                                        helpertext={
                                                            touched.typebotDelayMessage &&
                                                            errors.typebotDelayMessage
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        className={
                                                            classes.textField
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    xl={6}>
                                                    <Field
                                                        as={TextField}
                                                        label="Palavra para finalizar o ticket"
                                                        name="typebotKeywordFinish"
                                                        error={
                                                            touched.typebotKeywordFinish &&
                                                            Boolean(
                                                                errors.typebotKeywordFinish,
                                                            )
                                                        }
                                                        helpertext={
                                                            touched.typebotKeywordFinish &&
                                                            errors.typebotKeywordFinish
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        className={
                                                            classes.textField
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    xl={6}>
                                                    <Field
                                                        as={TextField}
                                                        label="Palavra para reiniciar o fluxo"
                                                        name="typebotKeywordRestart"
                                                        error={
                                                            touched.typebotKeywordRestart &&
                                                            Boolean(
                                                                errors.typebotKeywordRestart,
                                                            )
                                                        }
                                                        helpertext={
                                                            touched.typebotKeywordRestart &&
                                                            errors.typebotKeywordRestart
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        className={
                                                            classes.textField
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    xl={6}>
                                                    <Field
                                                        as={TextField}
                                                        label="Mensagem de opção inválida"
                                                        name="typebotUnknownMessage"
                                                        error={
                                                            touched.typebotUnknownMessage &&
                                                            Boolean(
                                                                errors.typebotUnknownMessage,
                                                            )
                                                        }
                                                        helpertext={
                                                            touched.typebotUnknownMessage &&
                                                            errors.typebotUnknownMessage
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        className={
                                                            classes.textField
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    xl={12}>
                                                    <Field
                                                        as={TextField}
                                                        label="Mensagem ao reiniciar a conversa"
                                                        name="typebotRestartMessage"
                                                        error={
                                                            touched.typebotRestartMessage &&
                                                            Boolean(
                                                                errors.typebotRestartMessage,
                                                            )
                                                        }
                                                        helpertext={
                                                            touched.typebotRestartMessage &&
                                                            errors.typebotRestartMessage
                                                        }
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        className={
                                                            classes.textField
                                                        }
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </DialogContent>
                            </Paper>

                            <DialogActions>
                                {values.type === 'dialogflow' && (
                                    <Button
                                        onClick={e =>
                                            handleTestSession(e, values)
                                        }
                                        color="inherit"
                                        disabled={isSubmitting}
                                        name="testSession"
                                        variant="outlined"
                                        className={classes.btnLeft}>
                                        Testar Bot
                                    </Button>
                                )}
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined">
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}>
                                    {integrationId ? 'Salvar' : 'Adicionar'}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}

export default QueueIntegration
