import React, { useState, useEffect } from 'react'
import Validator from 'helpers/Validator'
import { makeStyles, TextField, Grid, Container } from '@material-ui/core'
import { Formik, Form, FastField, FieldArray } from 'formik'
import { isArray } from 'lodash'
import NumberFormat from 'react-number-format'
import ButtonWithSpinner from '../ButtonWithSpinner'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    textfield: {
        width: '100%',
    },
    row: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    control: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    buttonContainer: {
        textAlign: 'right',
        padding: theme.spacing(1),
    },
}))

const timeValidation = value => {
    if (!value) return true

    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/
    if (!timeRegex.test(value)) return false

    const [hours, minutes] = value.split(':').map(Number)
    return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59
}

const ScheduleSchema = Validator.object().shape({
    schedules: Validator.array().of(
        Validator.object()
            .shape({
                startTime: Validator.string().test(
                    'timeValidation',
                    'O horário deve ser entre 00:00 e 23:59',
                    timeValidation,
                ),
                endTime: Validator.string().test(
                    'timeValidation',
                    'O horário deve ser entre 00:00 e 23:59',
                    timeValidation,
                ),
            })
            .test(
                'endTimeAfterStartTime',
                'O horário final deve ser maior ou igual ao inicial',
                value => {
                    const { startTime, endTime } = value
                    if (!startTime || !endTime) return true

                    const [startHours, startMinutes] = startTime.split(':').map(Number)
                    const [endHours, endMinutes] = endTime.split(':').map(Number)

                    // Apenas checa os minutos se for a mesma hora
                    if (startHours === endHours) return startMinutes <= endMinutes

                    return startHours <= endHours
                },
            ),
    ),
})

function SchedulesForm(props) {
    const { initialValues, onSubmit, loading, labelSaveButton } = props
    const classes = useStyles()

    const [schedules, setSchedules] = useState([
        {
            weekday: 'Segunda-feira',
            weekdayEn: 'monday',
            startTime: '',
            endTime: '',
        },
        {
            weekday: 'Terça-feira',
            weekdayEn: 'tuesday',
            startTime: '',
            endTime: '',
        },
        {
            weekday: 'Quarta-feira',
            weekdayEn: 'wednesday',
            startTime: '',
            endTime: '',
        },
        {
            weekday: 'Quinta-feira',
            weekdayEn: 'thursday',
            startTime: '',
            endTime: '',
        },
        {
            weekday: 'Sexta-feira',
            weekdayEn: 'friday',
            startTime: '',
            endTime: '',
        },
        {
            weekday: 'Sábado',
            weekdayEn: 'saturday',
            startTime: '',
            endTime: '',
        },
        { weekday: 'Domingo', weekdayEn: 'sunday', startTime: '', endTime: '' },
    ])

    useEffect(() => {
        if (isArray(initialValues) && initialValues.length > 0) {
            setSchedules(initialValues)
        }
    }, [initialValues])

    const handleSubmit = data => {
        onSubmit(data)
    }

    return (
        <Formik
            enableReinitialize
            className={classes.fullWidth}
            initialValues={{ schedules }}
            validationSchema={ScheduleSchema}
            onSubmit={({ schedules }) =>
                setTimeout(() => {
                    handleSubmit(schedules)
                }, 500)
            }>
            {({ values, touched, errors }) => (
                <Form className={classes.fullWidth}>
                    <FieldArray
                        name="schedules"
                        render={arrayHelpers => (
                            <Grid spacing={4} container>
                                {values.schedules.map((item, index) => {
                                    return (
                                        <Container>
                                            <FastField
                                                as={TextField}
                                                label="Dia da Semana"
                                                name={`schedules[${index}].weekday`}
                                                disabled
                                                variant="outlined"
                                                style={{
                                                    marginRight: '3.2%',
                                                    width: '30%',
                                                }}
                                                margin="dense"
                                            />
                                            <FastField
                                                name={`schedules[${index}].startTime`}>
                                                {({ field }) => (
                                                    <NumberFormat
                                                        label="Hora de Inicial"
                                                        {...field}
                                                        variant="outlined"
                                                        margin="dense"
                                                        customInput={TextField}
                                                        error={
                                                            touched.schedules?.[
                                                                index
                                                            ]?.startTime &&
                                                            Boolean(
                                                                errors
                                                                    .schedules?.[
                                                                    index
                                                                ]?.startTime,
                                                            )
                                                        }
                                                        helperText={
                                                            touched.schedules?.[
                                                                index
                                                            ]?.startTime &&
                                                            errors.schedules?.[
                                                                index
                                                            ]?.startTime
                                                        }
                                                        format="##:##"
                                                        style={{
                                                            marginRight: '3.2%',
                                                            width: '30%',
                                                        }}
                                                    />
                                                )}
                                            </FastField>
                                            <FastField
                                                name={`schedules[${index}].endTime`}>
                                                {({ field }) => (
                                                    <NumberFormat
                                                        label="Hora de Final"
                                                        {...field}
                                                        variant="outlined"
                                                        margin="dense"
                                                        customInput={TextField}
                                                        error={
                                                            touched.schedules?.[
                                                                index
                                                            ]?.endTime &&
                                                            Boolean(
                                                                errors
                                                                    .schedules?.[
                                                                    index
                                                                ]?.endTime,
                                                            )
                                                        }
                                                        helperText={
                                                            touched.schedules?.[
                                                                index
                                                            ]?.endTime &&
                                                            errors.schedules?.[
                                                                index
                                                            ]?.endTime
                                                        }
                                                        format="##:##"
                                                        style={{
                                                            marginRight: '3.2%',
                                                            width: '30%',
                                                        }}
                                                    />
                                                )}
                                            </FastField>

                                            {typeof errors.schedules?.[index] ==
                                                'string' &&
                                                errors.schedules?.[index]}
                                        </Container>
                                    )
                                })}
                            </Grid>
                        )}></FieldArray>
                    <div
                        style={{ textAlign: 'center', marginTop: '2%' }}
                        className={classes.buttonContainer}>
                        <ButtonWithSpinner
                            loading={loading}
                            type="submit"
                            color="primary"
                            variant="contained">
                            {labelSaveButton ?? 'Salvar'}
                        </ButtonWithSpinner>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default SchedulesForm
