import React, { useState, useEffect, useRef } from 'react'

import Validator from 'helpers/Validator'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import IconButton from '@material-ui/core/IconButton'
import { head } from 'lodash'
import api from '../../services/api'
import toastError from '../../errors/toastError'
import MessageVariablesPicker from '../MessageVariablesPicker'
import { Grid } from '@material-ui/core'
import ConfirmationModal from '../ConfirmationModal'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}))

const QuickeMessageSchema = Validator.object().shape({
    shortcode: Validator.string().required(),
    //   message: Validator.string().required(),
})

const QuickMessageDialog = ({ open, onClose, quickemessageId, reload }) => {
    const classes = useStyles()
    const messageInputRef = useRef()

    const initialState = {
        shortcode: '',
        message: '',
        geral: false,
        status: true,
    }

    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const [quickemessage, setQuickemessage] = useState(initialState)
    const [attachment, setAttachment] = useState(null)
    const attachmentFile = useRef(null)

    useEffect(() => {
        try {
            ;(async () => {
                if (!quickemessageId) return

                const { data } = await api.get(
                    `/quick-messages/${quickemessageId}`,
                )

                setQuickemessage(prevState => {
                    return { ...prevState, ...data }
                })
            })()
        } catch (err) {
            toastError(err)
        }
    }, [quickemessageId, open])

    const handleClose = () => {
        setQuickemessage(initialState)
        setAttachment(null)
        onClose()
    }

    const handleAttachmentFile = e => {
        const file = head(e.target.files)
        if (file) {
            setAttachment(file)
        }
    }

    const handleSaveQuickeMessage = async values => {
        const quickemessageData = {
            ...values,
            isMedia: true,
        }

        try {
            if (quickemessageId) {
                await api.put(
                    `/quick-messages/${quickemessageId}`,
                    quickemessageData,
                )
                if (attachment != null) {
                    const formData = new FormData()
                    formData.append('file', attachment)
                    await api.post(
                        `/quick-messages/${quickemessageId}/media-upload`,
                        formData,
                    )
                }
            } else {
                const { data } = await api.post(
                    '/quick-messages',
                    quickemessageData,
                )
                if (attachment != null) {
                    const formData = new FormData()
                    formData.append('file', attachment)
                    await api.post(
                        `/quick-messages/${data.id}/media-upload`,
                        formData,
                    )
                }
            }
            toast.success('Atalho adicionado com sucesso!')
            if (typeof reload == 'function') {
                reload()
            }
        } catch (err) {
            toastError(err)
        }
        handleClose()
    }

    const deleteMedia = async () => {
        if (attachment) {
            setAttachment(null)
            attachmentFile.current.value = null
        }

        if (quickemessage.mediaPath) {
            await api.delete(`/quick-messages/${quickemessage.id}/media-upload`)
            setQuickemessage(prev => ({
                ...prev,
            }))
            toast.success('Atalho removido com sucesso!')
            if (typeof reload == 'function') {
                reload()
            }
        }
    }

    const handleClickMsgVar = async (msgVar, setValueFunc) => {
        const el = messageInputRef.current
        const firstHalfText = el.value.substring(0, el.selectionStart)
        const secondHalfText = el.value.substring(el.selectionEnd)
        const newCursorPos = el.selectionStart + msgVar.length

        setValueFunc('message', `${firstHalfText}${msgVar}${secondHalfText}`)

        await new Promise(r => setTimeout(r, 100))
        messageInputRef.current.setSelectionRange(newCursorPos, newCursorPos)
    }

    return (
        <div className={classes.root}>
            <ConfirmationModal
                title="Exclusão"
                open={confirmationOpen}
                onClose={() => setConfirmationOpen(false)}
                onConfirm={deleteMedia}>
                Esta ação é irreversível! Deseja prosseguir?
            </ConfirmationModal>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {quickemessageId ? `Editar` : `Adicionar`}
                </DialogTitle>
                <div style={{ display: 'none' }}>
                    <input
                        type="file"
                        ref={attachmentFile}
                        onChange={e => handleAttachmentFile(e)}
                    />
                </div>
                <Formik
                    initialValues={quickemessage}
                    enableReinitialize={true}
                    validationSchema={QuickeMessageSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveQuickeMessage(values)
                            actions.setSubmitting(false)
                        }, 400)
                    }}>
                    {({
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        values,
                    }) => (
                        <Form>
                            <DialogContent dividers>
                                <Grid spacing={2} container>
                                    <Grid xs={12} item>
                                        <Field
                                            as={TextField}
                                            autoFocus
                                            label="Atalho"
                                            name="shortcode"
                                            error={
                                                touched.shortcode &&
                                                Boolean(errors.shortcode)
                                            }
                                            helperText={
                                                touched.shortcode &&
                                                errors.shortcode
                                            }
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Field
                                            as={TextField}
                                            label="Resposta"
                                            name="message"
                                            inputRef={messageInputRef}
                                            error={
                                                touched.message &&
                                                Boolean(errors.message)
                                            }
                                            helperText={
                                                touched.message &&
                                                errors.message
                                            }
                                            variant="outlined"
                                            margin="dense"
                                            multiline={true}
                                            rows={7}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item>
                                        <MessageVariablesPicker
                                            disabled={isSubmitting}
                                            onClick={value =>
                                                handleClickMsgVar(
                                                    value,
                                                    setFieldValue,
                                                )
                                            }
                                        />
                                    </Grid>
                                    {(quickemessage.mediaPath ||
                                        attachment) && (
                                        <Grid xs={12} item>
                                            <Button
                                                startIcon={<AttachFileIcon />}>
                                                {attachment
                                                    ? attachment.name
                                                    : quickemessage.mediaPath}
                                            </Button>
                                            <IconButton
                                                onClick={() =>
                                                    setConfirmationOpen(true)
                                                }
                                                color="secondary">
                                                <DeleteOutlineIcon color="secondary" />
                                            </IconButton>
                                        </Grid>
                                    )}
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                {!attachment && !quickemessage.mediaPath && (
                                    <Button
                                        color="primary"
                                        onClick={() =>
                                            attachmentFile.current.click()
                                        }
                                        disabled={isSubmitting}
                                        variant="outlined">
                                        Anexar Arquivo
                                    </Button>
                                )}
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined">
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}>
                                    {quickemessageId ? `Editar` : `Adicionar`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}

export default QuickMessageDialog
