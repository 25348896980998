const defaultInitial = {
    title: '',
}

const createLead = {
    ...defaultInitial,
    map: {
        name: '',
        email: '',
        phone: '',
        origin_details: '',
        message: '',
        notes: '',
        ads_campaign: '',
    },
    default: {
        purpose: '',
        origin_details: '',
        notes: '',
        ads_campaign: '',
    },
}

export { createLead }
