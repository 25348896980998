import React, { useContext, useEffect, useReducer, useState } from 'react'

import {
    Button,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper'
import TableRowSkeleton from '../../components/TableRowSkeleton'
import Title from '../../components/Title'
import toastError from '../../errors/toastError'
import api from '../../services/api'
import { AddCircleOutline, DeleteOutline, Edit } from '@material-ui/icons'
import QueueModal from '../../components/QueueModal'
import { toast } from 'react-toastify'
import ConfirmationModal from '../../components/ConfirmationModal'
import { SocketContext } from '../../context/Socket/SocketContext'
import useCan from 'hooks/useCan'

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const reducer = (state, action) => {
    if (action.type === 'LOAD_QUEUES') {
        const queues = action.payload
        const newQueues = []

        queues.forEach(queue => {
            const queueIndex = state.findIndex(q => q.id === queue.id)
            if (queueIndex !== -1) {
                state[queueIndex] = queue
            } else {
                newQueues.push(queue)
            }
        })

        return [...state, ...newQueues]
    }

    if (action.type === 'UPDATE_QUEUES') {
        const queue = action.payload
        const queueIndex = state.findIndex(u => u.id === queue.id)

        if (queueIndex !== -1) {
            state[queueIndex] = queue
            return [...state]
        } else {
            return [queue, ...state]
        }
    }

    if (action.type === 'DELETE_QUEUE') {
        const queueId = action.payload
        const queueIndex = state.findIndex(q => q.id === queueId)
        if (queueIndex !== -1) {
            state.splice(queueIndex, 1)
        }
        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const Queues = () => {
    const classes = useStyles()
    const socketManager = useContext(SocketContext)
    const { canOrReturn } = useCan()

    const [queues, dispatch] = useReducer(reducer, [])
    const [loading, setLoading] = useState(false)

    const [queueModalOpen, setQueueModalOpen] = useState(false)
    const [selectedQueue, setSelectedQueue] = useState(null)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            try {
                const { data } = await api.get('/queue')
                dispatch({ type: 'LOAD_QUEUES', payload: data })

                setLoading(false)
            } catch (err) {
                toastError(err)
                setLoading(false)
            }
        })()
    }, [])

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const socket = socketManager.GetSocket(companyId)

        socket.on(`company-${companyId}-queue`, data => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_QUEUES', payload: data.queue })
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_QUEUE', payload: data.queueId })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [socketManager])

    const handleOpenQueueModal = () => {
        setQueueModalOpen(true)
        setSelectedQueue(null)
    }

    const handleCloseQueueModal = () => {
        setQueueModalOpen(false)
        setSelectedQueue(null)
    }

    const handleEditQueue = queue => {
        setSelectedQueue(queue)
        setQueueModalOpen(true)
    }

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false)
        setSelectedQueue(null)
    }

    const handleDeleteQueue = async queueId => {
        try {
            await api.delete(`/queue/${queueId}`)
            toast.success('Setor excluído com sucesso!')
        } catch (err) {
            toastError(err)
        }
        setSelectedQueue(null)
    }

    if (
        !canOrReturn(
            [
                'department:page',
                'department:delete',
                'department:create',
                'department:update',
            ],
            '/tickets',
        )
    )
        return null

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    selectedQueue &&
                    `Excluir ${selectedQueue.name}?`
                }
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handleDeleteQueue(selectedQueue.id)}
            >
                Você tem certeza? Essa ação não pode ser revertida! Os atendimentos desse setor continuarão existindo, mas não terão mais nenhum setor atribuída.
            </ConfirmationModal>
            <QueueModal
                open={queueModalOpen}
                onClose={handleCloseQueueModal}
                queueId={selectedQueue?.id}
            />
            <MainHeader>
                <Title>Setores</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenQueueModal}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            style={{ gap: 4 }}>
                            <AddCircleOutline />
                            Adicionar
                        </Grid>
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                Nome
                            </TableCell>
                            <TableCell align="center">
                                Cor
                            </TableCell>
                            <TableCell align="center">
                                Ordenação do Setor
                            </TableCell>
                            <TableCell align="center">
                                Mensagem de saudação
                            </TableCell>
                            <TableCell align="center">
                                Ações
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {queues.map(queue => (
                                <TableRow key={queue.id}>
                                    <TableCell align="center">
                                        {queue.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        <div
                                            className={classes.customTableCell}>
                                            <span
                                                style={{
                                                    backgroundColor:
                                                        queue.color,
                                                    width: 60,
                                                    height: 20,
                                                    alignSelf: 'center',
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <div
                                            className={classes.customTableCell}>
                                            <Typography
                                                style={{
                                                    width: 300,
                                                    align: 'center',
                                                }}
                                                noWrap
                                                variant="body2">
                                                {queue.orderQueue}
                                            </Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <div
                                            className={classes.customTableCell}>
                                            <Typography
                                                style={{
                                                    width: 300,
                                                    align: 'center',
                                                }}
                                                noWrap
                                                variant="body2">
                                                {queue.greetingMessage}
                                            </Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleEditQueue(queue)
                                            }>
                                            <Edit />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedQueue(queue)
                                                setConfirmModalOpen(true)
                                            }}>
                                            <DeleteOutline />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Queues
