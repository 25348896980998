import { Done, DoneAll } from "@material-ui/icons";
import useStyles from "../useStyles";
import React from "react";



export default function Ack({ currentMessage }) {
    const classes = useStyles();

    const AckIcon = React.useMemo(() => ({
        1: <Done fontSize="small" className={classes.ackIcons} />,
        2: <Done fontSize="small" className={classes.ackIcons} />,
        3: <DoneAll fontSize="small" className={classes.ackIcons} />,
        4: <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />,
        5: <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />
    }), [classes.ackIcons, classes.ackDoneAllIcon])
    
    if (!currentMessage.fromMe) 
        return null

    return AckIcon[currentMessage.ack] ?? null
}