import React, { useState, useEffect, useRef } from 'react'

import Validator from 'helpers/Validator'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import api from '../../services/api'
import toastError from '../../errors/toastError'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import { cloneDeep } from 'lodash'

const useStyles = makeStyles(theme => ({
    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

    accordionSummary: {
        backgroundColor: theme.mode === 'light' ? '#efefef' : '#333333',
    },
}))

const RoleSchema = Validator.object().shape({
    name: Validator.string().min(2).max(50).required(),
    type: Validator.string().required(),
})

const RenderPermissions = ({ structure, module, ...props }) => {
    const classes = useStyles()
    const permissions = cloneDeep(props.values.permissions) || []
    const modulePermissions = permissions.filter(f => f.indexOf(module) === 0)
    const [collapsed, setCollapsed] = useState(!!modulePermissions.length)

    const changePermission = permission => {
        const permissionIndex = permissions.indexOf(permission)

        if (permissionIndex !== -1) permissions.splice(permissionIndex, 1)
        else permissions.push(permission)

        return props.setFieldValue('permissions', permissions)
    }

    return (
        <Accordion
            expanded={collapsed}
            onChange={() => setCollapsed(!collapsed)}
            TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{ root: classes.accordionSummary }}>
                <Typography variant="h6" component="h2">
                    {structure.name}
                </Typography>
            </AccordionSummary>

            <AccordionDetails>
                {structure.permissions.map(permission => {
                    return (
                        <Field
                            type="checkbox"
                            key={module + permission.key}
                            name="permissions"
                            value={`${module}:${permission.key}`}
                            as={FormControlLabel}
                            control={
                                <Checkbox
                                    value={`${module}:${permission.key}`}
                                    checked={permissions.includes(
                                        `${module}:${permission.key}`,
                                    )}
                                />
                            }
                            onChange={() =>
                                changePermission(`${module}:${permission.key}`)
                            }
                            label={permission.name}
                        />
                    )
                })}
            </AccordionDetails>
        </Accordion>
    )
}

const initialState = {
    name: '',
    type: 'admin',
    permissions: [],
}

const RoleModal = ({ open, onClose, roleId }) => {
    const classes = useStyles()
    const [structure, setStructure] = useState({})
    const [role, setRole] = useState(initialState)
    const formikReference = useRef()

    useEffect(() => {
        api.get('/role/structure').then(({ data }) => {
            setStructure(data)
        })
    }, [])

    useEffect(() => {
        if (!roleId) return

        api.get(`/role/${roleId}`, {
            include: {
                permissions: '*',
            },
        })
            .then(({ data }) => {
                const permissions = data.permissions?.map(p => p.permission)

                setRole(prevState => {
                    return { ...prevState, ...data, permissions }
                })
            })
            .catch(err => {
                toastError(err)
            })

        return () => setRole(initialState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleId, open])

    const handleClose = () => {
        onClose()
        setRole(initialState)
    }

    const handleSave = async values => {
        try {
            if (roleId) {
                await api.put(`/role/${roleId}`, {
                    ...values,
                })
            } else {
                await api.post('/role', {
                    ...values,
                })
            }
            toast.success('Cargo salvo com sucesso')
            handleClose()
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <Dialog
            maxWidth="md"
            fullWidth={true}
            open={open}
            onClose={handleClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <Formik
                innerRef={f => (formikReference.current = f)}
                initialValues={role}
                enableReinitialize={true}
                validationSchema={RoleSchema}
                onSubmit={(values, actions) => {
                    handleSave(values)
                    actions.setSubmitting(false)
                }}>
                {({
                    touched,
                    errors,
                    isSubmitting,
                    values,
                    handleSubmit,
                    ...props
                }) => (
                    <>
                        <DialogTitle id="scroll-dialog-title">
                            {roleId ? `Editando Cargo` : `Cadastrando cargo`}
                        </DialogTitle>
                        <DialogContent dividers>
                            <Form>
                                <Grid container spacing={1}>
                                    <Grid item xs={9}>
                                        <Field
                                            as={TextField}
                                            label="Nome"
                                            name="name"
                                            error={
                                                touched.name &&
                                                Boolean(errors.name)
                                            }
                                            helperText={
                                                touched.name && errors.name
                                            }
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControl
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            error={
                                                touched.type &&
                                                Boolean(errors.type)
                                            }>
                                            <InputLabel htmlFor="type-selection">
                                                Tipo
                                            </InputLabel>
                                            <Field
                                                as={Select}
                                                id="type-selection"
                                                label="Tipo"
                                                name="type"
                                                margin="dense"
                                                required>
                                                <MenuItem value="admin">
                                                    Administrador
                                                </MenuItem>
                                                <MenuItem value="user">
                                                    Usuário
                                                </MenuItem>
                                            </Field>
                                            {Boolean(errors.type) && (
                                                <FormHelperText>
                                                    {errors.type}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        multiline
                                        label="Observações"
                                        name="notes"
                                        rows={3}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>

                                {values.type !== 'admin' && (
                                    <>
                                        <Divider style={{ marginBlock: 15 }} />

                                        <Typography variant="h6" component="h2">
                                            Permissões
                                        </Typography>

                                        {Object.entries(structure).map(
                                            ([key, value]) => (
                                                <RenderPermissions
                                                    key={key}
                                                    module={key}
                                                    structure={value}
                                                    touched={touched}
                                                    errors={errors}
                                                    isSubmitting={isSubmitting}
                                                    values={values}
                                                    {...props}
                                                />
                                            ),
                                        )}
                                    </>
                                )}
                            </Form>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color="secondary"
                                disabled={isSubmitting}
                                variant="outlined">
                                Cancelar
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                type="submit"
                                color="primary"
                                disabled={isSubmitting}
                                variant="contained"
                                className={classes.btnWrapper}>
                                Salvar
                                {isSubmitting && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    )
}

export default RoleModal
