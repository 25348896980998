import { Field } from 'formik'
import FormikCustomFieldAutocomplete from 'components/CustomFieldAutocomplete/FormikCustomFieldAutocomplete'
import MessageWithPicker from '../../components/MessageWithPicker'
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import AskFormItems from './AskFormItems'
import { useCallback } from 'react'
import { cloneDeep } from 'lodash'
import { v4 as uuid } from 'uuid'

const AskForm = props => {
    const addOption = useCallback(() => {
        const newValues = cloneDeep(props.values)
        if (!newValues.items?.length) newValues.items = []

        newValues.items.push({ id: uuid(), description: '', save: '' })
        props.setValues(newValues)
    }, [props])

    const removeItem = index => {
        const items = props.touched.items
        items.splice(index, 1)
        props.setTouched({ ...props.touched, items })

        const newValues = cloneDeep(props.values)
        newValues.items.splice(index, 1) // Criado clone para não alterar o data por referencia

        props.setValues(newValues)
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    error={props.touched?.title && props.errors?.title}
                    helperText={props.touched?.title && props.errors?.title}
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Grid item xs={12}>
                <Field
                    as={FormikCustomFieldAutocomplete}
                    name="answer"
                    label="Salvar na variável"
                    fullWidth
                    variant="outlined"
                    error={props.touched?.answer && props.errors?.answer}
                    helperText={props.touched?.answer && props.errors?.answer}
                    {...props.getFieldProps('answer')}
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="type-selection">Tipo</InputLabel>
                    <Field
                        as={Select}
                        id="type-selection"
                        label="Tipo"
                        labelId="type-selection-label"
                        name="type"
                        required>
                        <MenuItem value="answer">Resposta Simples</MenuItem>
                        <MenuItem value="menu">Múltipla Escolha</MenuItem>
                        <MenuItem value="manual">
                            Preencher Manualmente
                        </MenuItem>
                    </Field>
                </FormControl>
            </Grid>

            {props.values.type !== 'manual' && (
                <Grid item xs={12}>
                    <MessageWithPicker
                        name="message"
                        label="Pergunta"
                        error={props.touched?.message && props.errors?.message}
                        helperText={
                            props.touched?.message && props.errors?.message
                        }
                        {...props.getFieldProps(`message`)}
                    />
                </Grid>
            )}

            {props.values.type === 'manual' && (
                <Grid item xs={12}>
                    <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        name="save"
                        label="Valor à salvar"
                        error={props.touched?.save && props.errors?.save}
                        helperText={props.touched?.save && props.errors?.save}
                        {...props.getFieldProps(`save`)}
                    />
                </Grid>
            )}

            {props.values.type === 'menu' && (
                <Grid item xs={12}>
                    <Button onClick={addOption} variant="contained">
                        Adicionar opção
                    </Button>

                    <Grid item xs={12}>
                        <hr />
                    </Grid>

                    {props.values.items?.map((_, index) => {
                        return (
                            <AskFormItems
                                item={index}
                                key={index}
                                onRemove={() => removeItem(index)}
                                {...props}
                            />
                        )
                    })}
                </Grid>
            )}

            <FormControlLabel
                control={
                    <Field
                        as={Switch}
                        color="primary"
                        name="ignoreIfFilled"
                        checked={props.values.ignoreIfFilled}
                    />
                }
                label="Ignorar se já estiver preenchido"
            />

            {/* <Alert style={{ width: '100%' }} severity="info">
                O Bot enviará a pergunta configurada e aguardará a resposta do
                contato.
                <br />A resposta ficará salva na variável configurada
            </Alert> */}
        </Grid>
    )
}

export default AskForm
