import React, { useContext, useEffect, useReducer, useState } from 'react'

import {
    Button,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper'
import TableRowSkeleton from '../../components/TableRowSkeleton'
import Title from '../../components/Title'
import toastError from '../../errors/toastError'
import api from '../../services/api'
import { AddCircleOutline, DeleteOutline, Edit } from '@material-ui/icons'
import { toast } from 'react-toastify'
import ConfirmationModal from '../../components/ConfirmationModal'
import { SocketContext } from '../../context/Socket/SocketContext'
import RoleModal from 'components/RoleModal'
import useCan from 'hooks/useCan'

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const reducer = (state, action) => {
    if (action.type === 'LOAD_ROLES') {
        const roles = action.payload
        const newRoles = []

        roles.forEach(role => {
            const index = state.findIndex(q => q.id === role.id)
            if (index !== -1) {
                state[index] = role
            } else {
                newRoles.push(role)
            }
        })

        return [...state, ...newRoles]
    }

    if (action.type === 'UPDATE_ROLES') {
        const role = action.payload
        const index = state.findIndex(u => u.id === role.id)

        if (index !== -1) {
            state[index] = role
            return [...state]
        } else {
            return [role, ...state]
        }
    }

    if (action.type === 'DELETE_ROLE') {
        const roleId = action.payload
        const index = state.findIndex(q => q.id === roleId)
        if (index !== -1) {
            state.splice(index, 1)
        }
        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const Roles = () => {
    const classes = useStyles()
    const socketManager = useContext(SocketContext)
    const { canOrReturn } = useCan()

    const [roles, dispatch] = useReducer(reducer, [])
    const [loading, setLoading] = useState(false)

    const [roleModalOpen, setRoleModalOpen] = useState(false)
    const [selectedRole, setSelectedRole] = useState(null)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            try {
                const { data } = await api.get('/role')
                dispatch({ type: 'LOAD_ROLES', payload: data.data })

                setLoading(false)
            } catch (err) {
                toastError(err)
                setLoading(false)
            }
        })()
    }, [])

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const socket = socketManager.GetSocket(companyId)

        socket.on(`company-${companyId}-role`, data => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_ROLES', payload: data.role })
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_ROLE', payload: data.role })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [socketManager])

    const handleOpenRoleModal = () => {
        setRoleModalOpen(true)
        setSelectedRole(null)
    }

    const handleCloseRoleModal = () => {
        setRoleModalOpen(false)
        setSelectedRole(null)
    }

    const handleEditRole = role => {
        setSelectedRole(role)
        setRoleModalOpen(true)
    }

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false)
        setSelectedRole(null)
    }

    const handleDeleteRole = async roleId => {
        try {
            await api.delete(`/role/${roleId}`)
            toast.success('Operação realizada com sucesso!')
        } catch (err) {
            toastError(err)
        }
        setSelectedRole(null)
    }

    if (
        !canOrReturn(
            ['role:page', 'role:delete', 'role:create', 'role:update'],
            '/tickets',
        )
    )
        return null

    return (
        <MainContainer>
            <ConfirmationModal
                title={selectedRole && `Deletar ${selectedRole.name}?`}
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handleDeleteRole(selectedRole.id)}>
                Você tem certeza? Essa ação não pode ser revertida!
            </ConfirmationModal>
            <RoleModal
                open={roleModalOpen}
                onClose={handleCloseRoleModal}
                roleId={selectedRole?.id}
            />
            <MainHeader>
                <Title>Cargos</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenRoleModal}
                    >
                        <Grid container justifyContent='center' alignItems="center" style={{ gap: 4 }}>
                            <AddCircleOutline />
                            Adicionar
                        </Grid>
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {roles.map(role => (
                                <TableRow key={role.id}>
                                    <TableCell align="center">
                                        {role.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleEditRole(role)
                                            }>
                                            <Edit />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedRole(role)
                                                setConfirmModalOpen(true)
                                            }}>
                                            <DeleteOutline />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={2} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Roles
