import React, { useEffect, useState } from 'react'
import { Chip, makeStyles } from '@material-ui/core'
import OutlinedDiv from '../OutlinedDiv'
import api from '../../services/api'

const useStyles = makeStyles(theme => ({
    outlinedRoot: {
        '& > div': {
            maxHeight: 120,
            overflow: 'auto',
            alignItems: 'flex-start',
        },
    },
    chip: {
        margin: theme.spacing(0.5),
        cursor: 'pointer',
        color: 'white',
        backgroundColor: theme.palette.apre.dark,
    },
    custom: {
        backgroundColor: theme.palette.apre.main,
    },
    added: {
        backgroundColor: theme.palette.apre.light,
    },
}))

const defaultVariables = [
    {
        name: 'Primeiro Nome',
        value: '{{firstName}}',
    },
    {
        name: 'Nome',
        value: '{{name}}',
    },
    {
        name: 'Saudação',
        value: '{{ms}}',
    },
    {
        name: 'Protocolo',
        value: '{{protocol}}',
    },
    {
        name: 'Data',
        value: '{{date}}',
    },
    {
        name: 'Hora',
        value: '{{hour}}',
    },
]

const MessageVariablesPicker = ({ onClick, disabled, customVariables }) => {
    const classes = useStyles()
    const [variables, setVariables] = useState(defaultVariables)

    useEffect(() => {
        if (customVariables?.length) {
            if (customVariables.filter(v => !v.name || !v.value).length)
                throw new Error("customVariable needs to have name and value");

            setVariables(old => [
                ...old,
                ...customVariables.map(v => ({
                    name: v.name,
                    value: !v.value.startsWith('{{') && !v.value.endsWith('}}') ? `{{${v.value}}}` : v.value,
                    added: true
                })),
            ])
        }
    }, [customVariables])

    const handleClick = (e, value) => {
        e.preventDefault()
        if (disabled) return
        onClick(value)
    }

    useEffect(() => {
        const getAllFields = async () => {
            let page = 0
            let total = 0
            const addVariables = []

            do {
                const response = (
                    await api
                        .get('/custom-field', {
                            page: ++page,
                        })
                        .catch(err => console.error(err))
                ).data

                total = response.total
                addVariables.push(
                    ...response.data.map(field => ({
                        name: field.name,
                        value: `{{custom ${field.name}}}`,
                        custom: true,
                    })),
                )
            } while (total > addVariables.length)

            setVariables(old => [...old, ...addVariables])
        }

        getAllFields()
    }, [])

    return (
        <OutlinedDiv
            margin="dense"
            fullWidth
            label="Variáveis disponíveis"
            InputProps={{ className: classes.outlinedRoot }}
            disabled={disabled}>
            {variables.map(variable => (
                <Chip
                    key={variable.value}
                    onMouseDown={e => handleClick(e, variable.value)}
                    label={variable.name}
                    size="small"
                    className={[
                        classes.chip,
                        variable.custom ? classes.custom : null,
                        variable.added ? classes.added : null,
                    ].join(' ')}
                />
            ))}
        </OutlinedDiv>
    )
}

export default MessageVariablesPicker

