import React from 'react'

import api from '../../services/api'
import toastError from '../../errors/toastError'
import { AuthContext } from '../../context/Auth/AuthContext'

const getCurrentUserInfo = async () => {
    try {
        const { data } = await api.get('/auth/me')
        return data
    } catch (err) {
        toastError(err)
    }
}

const useAuth = () => {
    const { loading, user, isAuth, handleLogin, handleLogout } = React.useContext(AuthContext)

    return {
        isAuth,
        user,
        loading,
        handleLogin,
        handleLogout,
        getCurrentUserInfo,
    }
}

export default useAuth
