import { parseISO, format } from 'date-fns'
import useStyles from "../useStyles"

export default function TicketNumber({ currentMessage, previousMessage, nextMessage }) {
    const classes = useStyles()

    if (!previousMessage || !nextMessage || currentMessage.ticketId === previousMessage.ticketId)
        return null

    return (
        <span
            className={classes.dailyTimestamp}
            key={`ticket-${currentMessage.id}`}
            style={{ width: 'auto' }}
        >
            <div className={classes.dailyTimestampText}>
                Atendimento #{currentMessage.ticketId}{' '}
                {format(parseISO(currentMessage.createdAt), 'dd/MM/yyyy HH:mm:ss')}
            </div>
        </span>
    )
}