import useCan from 'hooks/useCan'

const Can = ({ perform, children }) => {
    const { can } = useCan()

    if (can(perform)) return children

    return null
}

export { Can }
