import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import CustomNodeBase from '../../components/CustomNodeBase'
import TimerIcon from '@material-ui/icons/Timer'
import { Box } from '@material-ui/core'
import { useRef } from 'react'

function SleepNode(myNode) {
    const { data, ...props } = myNode
    const refCustomNode = useRef(null)

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase ref={refCustomNode} node={myNode} name="Espera" icon={TimerIcon}>
                {data.time
                    ? data.time + ' Segundos'
                    : <Box onClick={() => refCustomNode.current?.open()}>
                        Clique para definir o tempo!
                    </Box>}
            </CustomNodeBase>
            <CustomStartHandle position={Position.Bottom} nodeId={props.id} />
        </div>
    )
}

export default SleepNode
