import React, { useContext, useEffect, useState } from 'react'
import QRCode from 'qrcode.react'
import toastError from '../../errors/toastError'

import { Dialog, DialogContent, Paper, Typography } from '@material-ui/core'
import api from '../../services/api'
import { SocketContext } from '../../context/Socket/SocketContext'

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
    const [qrCode, setQrCode] = useState('')
    const sessionManager = useContext(SocketContext)

    useEffect(() => {
        const fetchSession = async () => {
            if (!whatsAppId) return

            try {
                const { data } = await api.get(`/whatsapp/${whatsAppId}`)
                setQrCode(data.qrcode)
            } catch (err) {
                toastError(err)
            }
        }
        fetchSession()
    }, [whatsAppId])

    useEffect(() => {
        if (!whatsAppId) return
        const companyId = localStorage.getItem('companyId')
        const socket = sessionManager.GetSocket(companyId)

        socket.on(`company-${companyId}-whatsappSession`, data => {
            if (data.action === 'update' && data.session.id === whatsAppId) {
                setQrCode(data.session.qrcode)
            }

            if (data.action === 'update' && data.session.qrcode === '') {
                onClose()
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [whatsAppId, onClose, sessionManager])

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
            <DialogContent>
                <Paper elevation={0}>
                    <Typography color="primary" gutterBottom>
                        Leia o QrCode para iniciar a sessão
                    </Typography>
                    {qrCode ? (
                        <QRCode value={qrCode} size={256} />
                    ) : (
                        <span>Waiting for QR Code</span>
                    )}
                </Paper>
            </DialogContent>
        </Dialog>
    )
}

export default React.memo(QrcodeModal)
