import { Button, IconButton, Popover, Tooltip } from '@material-ui/core'
import { useReactFlow } from 'reactflow'
import NodeConfig from '../NodeConfig'
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import styles from '../../general.module.scss'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AddCommentIcon from '@material-ui/icons/AddComment'

function CustomNodeBase({
    children,
    node,
    icon,
    name,
    containerClass,
    noContainer = false,
    noOptions = false,
    canDelete = true,
    canEdit = true,
}, ref) {
    const [isOpen, setIsOpen] = useState(false)
    const reactFlowInstance = useReactFlow()
    const [optionsComponent, setOptionsComponent] = useState()

    const handleRemove = () => {
        if (node.type === 'start') return
        reactFlowInstance.deleteElements({ nodes: [{ id: node.id }] })
    }

    const handleChange = value => {
        reactFlowInstance.setNodes(nodes => {
            return nodes.map(oldNode => {
                if (oldNode.id !== node.id) return oldNode

                return { ...value, position: { x: value.xPos, y: value.yPos } }
            })
        })
        setIsOpen(false)
    }

    const handleOptions = event => {
        setOptionsComponent(event.currentTarget)
    }

    const renderIcon = () => {
        if (!icon)
            return <AddCommentIcon />

        // Caso seja enviado um ícone tipo <Icon /> ao invés da referencia direta ao componente
        if ('_owner' in icon) return icon

        const IconComp = icon
        return <IconComp />
    }

    const getRef = useCallback(() => ({
        open: () => setIsOpen(true),
    }), []);

    useImperativeHandle(ref, getRef, [getRef]);

    return (
        <div>
            <div className={styles.customNode}>
                <div className={styles.title}>
                    <Tooltip title={name} placement="top">
                        {renderIcon()}
                    </Tooltip>
                    <span>{node.data.title || name}</span>

                    {!noOptions && (
                        <IconButton
                            size="small"
                            onClick={handleOptions}
                            aria-describedby={node.id}
                            style={{ marginLeft: 'auto' }}>
                            <MoreVertIcon />
                        </IconButton>
                    )}
                </div>
                {!!children && !noContainer && (
                    <div className={[styles.content, containerClass].join(' ')}>
                        {children}
                    </div>
                )}
                {!!children && noContainer && children}
            </div>

            <NodeConfig
                node={node}
                onChange={handleChange}
                onClose={() => setIsOpen(false)}
                onDelete={handleRemove}
                isOpen={isOpen}
            />

            <Popover
                classes={{ paper: styles.popoverContainer }}
                open={!!optionsComponent}
                anchorEl={optionsComponent}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={() => setOptionsComponent(null)}>
                {canEdit && (
                    <Button size="small" onClick={setIsOpen}>
                        <EditIcon /> Editar
                    </Button>
                )}
                {canDelete && (
                    <Button size="small" onClick={handleRemove}>
                        <DeleteIcon /> Deletar
                    </Button>
                )}
            </Popover>
        </div>
    )
}

export default forwardRef(CustomNodeBase)
