import {
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@material-ui/core'
import { Field } from 'formik'
import { Alert } from '@material-ui/lab'
import { FormikFieldSelect } from './FieldSelect'

const CreateLeadForm = props => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Divider style={{ marginBlock: 10, width: '100%' }} />

            <Grid item xs={12}>
                <Field
                    as={FormikFieldSelect}
                    variant="outlined"
                    fullWidth
                    name="map.name"
                    label="Nome"
                    required
                    {...props.getFieldProps(`map.name`)}
                />
            </Grid>

            <Grid item xs={6}>
                <Field
                    as={FormikFieldSelect}
                    variant="outlined"
                    fullWidth
                    name="map.email"
                    label="E-mail"
                    {...props.getFieldProps(`map.email`)}
                />
            </Grid>

            <Grid item xs={6}>
                <Field
                    as={FormikFieldSelect}
                    variant="outlined"
                    fullWidth
                    name="map.phone"
                    label="Telefone"
                    {...props.getFieldProps(`map.phone`)}
                />
            </Grid>

            <Grid item xs={6}>
                <Field
                    as={FormikFieldSelect}
                    variant="outlined"
                    fullWidth
                    name="map.origin_details"
                    label="Detalhes da origem"
                    {...props.getFieldProps(`map.origin_details`)}
                />
            </Grid>

            <Grid item xs={6}>
                <Field
                    as={FormikFieldSelect}
                    variant="outlined"
                    fullWidth
                    name="map.message"
                    label="Mensagem"
                    {...props.getFieldProps(`map.message`)}
                />
            </Grid>

            <Grid item xs={6}>
                <Field
                    as={FormikFieldSelect}
                    variant="outlined"
                    fullWidth
                    name="map.notes"
                    label="Observações"
                    {...props.getFieldProps(`map.notes`)}
                />
            </Grid>

            <Grid item xs={6}>
                <Field
                    as={FormikFieldSelect}
                    variant="outlined"
                    fullWidth
                    name="map.ads_campaign"
                    label="Campanha ADS"
                    {...props.getFieldProps(`map.ads_campaign`)}
                />
            </Grid>

            <FormControlLabel
                control={
                    <Field
                        as={Switch}
                        color="primary"
                        name="sendFiles"
                        checked={props.values.sendFiles}
                    />
                }
                label="Enviar arquivos do atendimento"
            />

            <Alert style={{ width: '100%' }} severity="info">
                Campos não mapeados serão salvos como{' '}
                <b>Informações adicionais</b> do Lead
            </Alert>
            {Boolean(props.values?.sendFiles) && (
                <Alert style={{ width: '100%' }} severity="info">
                    Até <b>30</b> arquivos enviados pelo cliente neste
                    atendimento serão enviados em anexo ao Lead. Lembrando que
                    nem todas extensões de arquivos são aceitas, dê preferencia
                    à arquivos de <b>imagens e PDF</b>
                </Alert>
            )}
            <Divider style={{ marginBlock: 10, width: '100%' }} />

            <Alert style={{ width: '100%' }} severity="info">
                Esta etapa serve para mapear valores padrões. Caso o mesmo campo
                seja preenchido no formulário abaixo e acima, a informação acima
                será usada como prioritária
            </Alert>

            <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="purpose">Finalidade</InputLabel>
                    <Field
                        as={Select}
                        id="purpose"
                        label="Finalidade"
                        labelId="purpose-label"
                        name="default.purpose"
                        {...props.getFieldProps(`default.purpose`)}>
                        <MenuItem value="">Selecione</MenuItem>
                        <MenuItem value="sale">Venda</MenuItem>
                        <MenuItem value="rent">Aluguel</MenuItem>
                        <MenuItem value="season">Temporada</MenuItem>
                        <MenuItem value="exchange">Permuta</MenuItem>
                    </Field>
                </FormControl>
            </Grid>

            <Grid item xs={6}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="default.origin_details"
                    label="Detalhes da origem"
                    {...props.getFieldProps(`default.origin_details`)}
                />
            </Grid>

            <Grid item xs={6}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="default.notes"
                    label="Observações"
                    {...props.getFieldProps(`default.notes`)}
                />
            </Grid>

            <Grid item xs={6}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="default.ads_campaign"
                    label="Campanha ADS"
                    {...props.getFieldProps(`default.ads_campaign`)}
                />
            </Grid>
        </Grid>
    )
}

export default CreateLeadForm
