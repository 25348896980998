import React, { useCallback, useEffect, useState } from 'react'
import ReactFlow, {
    MiniMap,
    Controls,
    ReactFlowProvider,
    useNodesState,
    useEdgesState,
    reconnectEdge,
} from 'reactflow'
import Validator from 'helpers/Validator'
import { addEdge, useReactFlow } from 'reactflow'
import ChatOptions from './ChatOptions'
import { v4 as uuid } from 'uuid'
import styles from './general.module.scss'

import 'reactflow/dist/style.css'

import { useParams, useHistory, useLocation } from 'react-router-dom'
import api from '../../services/api'
import toastError from '../../errors/toastError'
import { toast } from 'react-toastify'
import ChatHeader from './ChatHeader'
import MessageNode from './nodes/MessageNode'
import MenuNode from './nodes/MenuNode'
import SleepNode from './nodes/SleepNode'
import AskNode from './nodes/AskNode'
import StartNode from './nodes/StartNode'
import QueueNode from './nodes/QueueNode'
import UserNode from './nodes/UserNode'
import FinishNode from './nodes/FinishNode'
import NotifyNode from './nodes/NotifyNode'
import ConditionNode from './nodes/ConditionNode'
import TagNode from './nodes/TagNode'
import { Form, Formik } from 'formik'
import FindPersonNode from './nodes/FindPersonNode'
import SendInvoiceNode from './nodes/SendInvoiceNode'
import { useTheme } from '@material-ui/styles'
import HttpNode from './nodes/HttpNode'
import ConnectionNode from './nodes/ConnectionNode'
import useCan from 'hooks/useCan'
import CreateLeadNode from './nodes/CreateLeadNode'

const nodeTypes = {
    start: StartNode,
    finish: FinishNode,
    message: MessageNode,
    menu: MenuNode,
    sleep: SleepNode,
    ask: AskNode,
    queue: QueueNode,
    user: UserNode,
    notify: NotifyNode,
    condition: ConditionNode,
    tag: TagNode,
    findPerson: FindPersonNode,
    sendInvoice: SendInvoiceNode,
    httpRequest: HttpNode,
    connectionTransfer: ConnectionNode,
    createLead: CreateLeadNode,
}

const initialNodes = [
    { id: uuid(), type: 'start', position: { x: -100, y: -100 }, data: {} },
    {
        id: uuid(),
        type: 'menu',
        position: { x: 0, y: 0 },
        data: {
            message: 'Mensagem do Menu',
            items: [
                { id: uuid(), message: 'primeiro item' },
                { id: uuid(), message: 'segundo item' },
            ],
        },
    },
    {
        id: uuid(),
        type: 'message',
        position: { x: 250, y: 50 },
        data: { message: 'Opção 1' },
    },
    {
        id: uuid(),
        type: 'message',
        position: { x: 100, y: 100 },
        data: { message: 'Opção 2' },
    },
]

const initialEdges = [
    {
        id: uuid(),
        animated: true,
        source: initialNodes[0].id,
        target: initialNodes[1].id,
    },
    {
        id: uuid(),
        animated: true,
        source: initialNodes[1].id,
        sourceHandle: initialNodes[1].data.items[0].id,
        target: initialNodes[2].id,
    },
    {
        id: uuid(),
        animated: true,
        source: initialNodes[1].id,
        sourceHandle: initialNodes[1].data.items[1].id,
        target: initialNodes[3].id,
    },
]

const FlowSchema = Validator.object().shape({
    name: Validator.string().required(),
    nodes: Validator.array().of(
        Validator.object({
            id: Validator.string().required(),
            type: Validator.string().required(),
            data: Validator.object().required(),
        }),
    ),
    edges: Validator.array().of(
        Validator.object({
            id: Validator.string().required(),
            source: Validator.string().required(),
            target: Validator.string().required(),
        }),
    ),
})

const defaultEdgeOptions = { animated: true, reconnectable: 'target' }

function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

const Flow = () => {
    const theme = useTheme()
    const { flowId } = useParams()
    const router = useHistory()
    const query = useQuery()
    const reactFlowInstance = useReactFlow()
    const { canOrReturn } = useCan()

    const [flow, setFlow] = useState({ id: flowId, name: query.get('name') || '' })
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)

    const assignFlow = useCallback(
        flow => {
            setFlow(prevState => ({ ...prevState, ...flow }))
            setNodes(flow.nodes)
            setEdges(flow.edges)
        },
        [setEdges, setNodes, setFlow],
    )

    useEffect(() => {
        if (!flow.id) return
        api.get(`/chat-flow/${flow.id}`)
            .then(({ data }) =>
                assignFlow({ ...data, edges: data.edges, nodes: data.nodes }),
            )
            .catch(err => toastError(err))
    }, [flow.id, assignFlow])

    const handleSaveFlow = async (values) => {
        const flowInstance = reactFlowInstance.toObject()
        const flowData = {
            ...values,
            nodes: flowInstance.nodes,
            edges: flowInstance.edges,
        }

        try {
            if (flow.id) {
                await api.put(`/chat-flow/${flow.id}`, flowData)
            } else {
                const newFlow = (await api.post('/chat-flow', flowData)).data
                router.push('/chat-flow/' + newFlow.id)
                assignFlow(newFlow)
            }

            toast.success('Fluxo salvo com sucesso!')
        } catch (err) {
            toastError(err)
        }
    }

    const addNode = useCallback(
        (type, attributes) =>
            reactFlowInstance.addNodes({
                id: uuid(),
                type,
                position: { x: 0, y: 0 },
                data: {},
                ...attributes,
            }),
        [reactFlowInstance],
    )
    const handleNodesChange = change => {
        change = change.filter(action => {
            if (action.type !== 'remove') return true

            const node = nodes.find(n => n.id === action.id)
            return node.type !== 'start'
        })

        const nodesRemoved = change
            .filter(n => n.type === 'remove')
            .map(n => n.id)
        const edgesToRemove = edges
            .filter(
                ed =>
                    nodesRemoved.includes(ed.target) ||
                    nodesRemoved.includes(ed.source),
            )
            .map(ed => ({ id: ed.id, type: 'remove' }))

        if (edgesToRemove.length) onEdgesChange(edgesToRemove)

        onNodesChange(change)
    }

    const onDragOver = useCallback(event => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
    }, [])
    const onDrop = useCallback(
        event => {
            event.preventDefault()

            const type = event.dataTransfer.getData('application/reactflow')

            // check if the dropped element is valid
            if (!Object.keys(nodeTypes).includes(type)) return

            const position = reactFlowInstance.screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            })

            addNode(type, { position })
        },
        [reactFlowInstance, addNode],
    )

    const onConnect = useCallback(
        connection => setEdges(eds => addEdge(connection, eds)),
        [setEdges],
    )
    const removeEdge = useCallback(
        (_, edge) => setEdges(edges.filter(ed => ed.id !== edge.id)),
        [setEdges, edges],
    )
    const onReconnect = useCallback(
        (oldEdge, newConnection) => setEdges(eds => reconnectEdge(oldEdge, newConnection, eds)),
        [setEdges]
    )

    if (!canOrReturn(['chatFlow:create', 'chatFlow:update'], '/chatflow'))
        return null

    return (
        <Formik
            validationSchema={FlowSchema}
            initialValues={flow}
            enableReinitialize={true}
            onSubmit={handleSaveFlow}>
            {props => (
                <Form
                    style={{
                        width: '100%',
                        height: `calc(100% - ${theme.appBarHeight})`,
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                    }}
                    onSubmit={props.handleSubmit}>
                    <ChatHeader
                        saveFlow={props.handleSubmit}
                        toList={() => router.push('/chat-flows')}
                        {...props}
                    />
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexGrow: 1,
                            position: 'relative',
                        }}>
                        <ReactFlow
                            nodes={nodes}
                            onNodesChange={handleNodesChange}
                            nodeTypes={nodeTypes}
                            edges={edges}
                            onReconnect={onReconnect}
                            onConnect={onConnect}
                            onEdgeClick={removeEdge}
                            defaultEdgeOptions={defaultEdgeOptions}
                            proOptions={{ hideAttribution: true }}
                            fitView
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            deleteKeyCode={['Delete']}
                            className={styles.reactFlow}>
                            <MiniMap pannable />
                            <Controls />
                        </ReactFlow>
                        <ChatOptions
                            reactFlowInstance={reactFlowInstance}
                            onAddNode={addNode}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const ChatFlow = () => {
    return (
        <ReactFlowProvider>
            <Flow />
        </ReactFlowProvider>
    )
}

export default ChatFlow
