import React, { useState, useEffect, useContext, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'
import clsx from 'clsx'

import { Paper, makeStyles } from '@material-ui/core'

import ContactDrawer from '../ContactDrawer'
import MessageInput from '../MessageInputCustom/'
import TicketHeader from '../TicketHeader'
import TicketInfo from '../TicketInfo'
import TicketActionButtons from '../TicketActionButtonsCustom'
import MessagesList from '../MessagesList'
import api from '../../services/api'
import { ReplyMessageProvider } from '../../context/ReplyingMessage/ReplyingMessageContext'
import toastError from '../../errors/toastError'
import { AuthContext } from '../../context/Auth/AuthContext'
import { TagsContainer } from '../TagsContainer'
import { SocketContext } from '../../context/Socket/SocketContext'
import useCan from 'hooks/useCan'

const drawerWidth = 320

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
    },

    mainWrapper: {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        borderRadius: '8px',
        borderLeft: '0',
        marginRight: -drawerWidth,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    mainWrapperShift: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}))

const Ticket = ({ ticketId, ignoreEscape = false, noActions = false }) => {
    const params = useParams()
    const { can } = useCan()

    if (!ticketId && params.ticketId) ticketId = params.ticketId

    const childRef = useRef()

    const history = useHistory()
    const classes = useStyles()
    const socketManager = useContext(SocketContext)

    const { user } = useContext(AuthContext)

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [contact, setContact] = useState({})
    const [ticket, setTicket] = useState({})

    const [messageId, setMessageId] = useState(null)

    useEffect(() => {
        if (!ignoreEscape) {
            const handleKeyDown = event => {
                if (event.key === 'Escape') {
                    history.push('/tickets')
                }
            }

            window.addEventListener('keydown', handleKeyDown)

            return () => {
                window.removeEventListener('keydown', handleKeyDown)
            }
        }
    }, [history, ignoreEscape])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            const fetchTicket = async () => {
                try {
                    const { data } = await api.get('/tickets/u/' + ticketId)
                    const { queueId } = data
                    const { queues } = user

                    const queueAllowed = queues.find(q => q.id === queueId)
                    if (
                        queueId &&
                        queueAllowed === undefined &&
                        !can('ticket:see:all:department')
                    ) {
                        toast.error(
                            'Você não tem permissão para visualizar tickets desse setor',
                        )
                        history.push('/tickets')
                        return
                    }

                    if (!queueId && !can('ticket:see:without:department')) {
                        toast.error(
                            'Você não tem permissão para visualizar tickets sem setor',
                        )
                        history.push('/tickets')
                        return
                    }

                    setContact(data.contact)
                    setTicket(data)
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }
            fetchTicket()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketId, user, history])

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const socket = socketManager.GetSocket(companyId)

        socketManager.onConnect(() =>
            socket.emit('joinChatBox', `${ticket.id}`),
        )

        socket.on(`company-${companyId}-ticket`, data => {
            if (data.ticketId !== ticket.id) return

            if (data.action === 'update') {
                setTicket(data.ticket)
            }

            if (data.action === 'delete') {
                toast.success('Ação realizada com sucesso.')
                history.push('/tickets')
            }
        })

        socket.on(`company-${companyId}-contact`, data => {
            if (data.action === 'update') {
                setContact(prevState => {
                    if (prevState.id === data.contact?.id) {
                        return { ...prevState, ...data.contact }
                    }
                    return prevState
                })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [ticketId, ticket, history, socketManager])

    const handleDrawerOpen = () => {
        setDrawerOpen(true)
    }

    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }

    const handleFocus = event => {
        // Se o clique não foi no background do ticket, ignoramos ele
        if (event.target !== event.currentTarget) return

        childRef.current.focus()
    }

    return (
        <div className={classes.root} id="drawer-container">
            <Paper
                variant="outlined"
                elevation={0}
                className={clsx(classes.mainWrapper, {
                    [classes.mainWrapperShift]: drawerOpen,
                })}>
                <TicketHeader loading={loading}>
                    {ticket.user !== undefined && (
                        <TicketInfo
                            contact={contact}
                            ticket={ticket}
                            onClick={handleDrawerOpen}
                        />
                    )}
                    <TicketActionButtons
                        ticket={ticket}
                        onSearch={msg => setMessageId(msg.id)}
                        noActions={noActions}
                    />
                </TicketHeader>
                <Paper>
                    <TagsContainer ticket={ticket} />
                </Paper>
                <ReplyMessageProvider>
                    <MessagesList
                        ticket={ticket}
                        ticketId={ticket.id}
                        isGroup={ticket.isGroup}
                        messageFocusedId={messageId}
                        backgroundClick={handleFocus}
                    />
                    <MessageInput
                        ticketId={ticket.id}
                        ticketStatus={ticket.status}
                        inputRef={childRef}
                    />
                </ReplyMessageProvider>
            </Paper>
            <ContactDrawer
                open={drawerOpen}
                handleDrawerClose={handleDrawerClose}
                contact={contact}
                loading={loading}
                ticket={ticket}
            />
        </div>
    )
}

export default Ticket
