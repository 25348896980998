import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Login from '../pages/Login/'

import { AuthProvider } from '../context/Auth/AuthContext'
import { TicketsContextProvider } from '../context/Tickets/TicketsContext'
import { WhatsAppsProvider } from '../context/WhatsApp/WhatsAppsContext'
import Route from './Route'

import General from './General'
import { ImagesProvider } from '../context/Company/ImagesContext'

const Routes = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                    <Switch>
                        <Route path="/login/:companyId(\d+)?" component={Login} />
                        <ImagesProvider>
                            <TicketsContextProvider>
                                <WhatsAppsProvider>
                                    <General />
                                </WhatsAppsProvider>
                            </TicketsContextProvider>
                        </ImagesProvider>
                    </Switch>
                <ToastContainer autoClose={3000} />
            </AuthProvider>
        </BrowserRouter>
    )
}

export default Routes
