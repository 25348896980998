import React, { useState, useEffect, useReducer, useRef, useContext } from 'react'
import { green } from '@material-ui/core/colors'
import { CircularProgress, makeStyles } from '@material-ui/core'
import MessageOptionsMenu from '../MessageOptionsMenu'
import toastError from '../../errors/toastError'
import { SocketContext } from '../../context/Socket/SocketContext'
import ReactingMessage from 'components/ReactingMessage'
import useMessages from 'hooks/useMessages'
import MessageCall from './components/MessageCall'
import Message, { SIDES } from './components/Message'
import useImages from 'hooks/useImages'

const reducer = (state, action) => {
    if (action.type === 'LOAD_MESSAGES') {
        const messages = action.payload
        const push = action.push
        const newMessages = []
        
        messages.forEach(message => {
            const messageIndex = state.findIndex(m => m.id === message.id)
            if (messageIndex !== -1) {
                state[messageIndex] = message
            } else {
                newMessages.push(message)
            }
        })

        return push ? [...state, ...newMessages] : [...newMessages, ...state]
    }

    if (action.type === 'ADD_MESSAGE') {
        const newMessage = action.payload
        const messageIndex = state.findIndex(m => m.id === newMessage.id)

        if (messageIndex !== -1) {
            state[messageIndex] = newMessage
        } else {
            state.push(newMessage)
        }

        return [...state]
    }

    if (action.type === 'DELETE_MESSAGE') {
        const messageToDelete = action.payload
        const messageIndex = state.findIndex(m => m.id === messageToDelete.id)

        if (messageIndex !== -1) state.splice(messageIndex, 1)

        return [...state]
    }

    if (action.type === 'UPDATE_MESSAGE') {
        const messageToUpdate = action.payload
        const messageIndex = state.findIndex(m => m.id === messageToUpdate.id)

        if (messageIndex !== -1) state[messageIndex] = messageToUpdate

        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const MessagesList = ({ ticket, ticketId, messageFocusedId, backgroundClick }) => {
    const { whatsappBackgroundLight, whatsappBackgroundDark } = useImages()
    const { search, list } = useMessages()

    const classes = useStyles({whatsappBackgroundLight, whatsappBackgroundDark})()
    const socketManager = useContext(SocketContext)

    const isFirstRender = useRef(true)
    const scrollDown = useRef(false)
    const messagesRef = useRef({})
    const refBottom = useRef()

    const [messagesList, dispatch] = useReducer(reducer, [])
    const [loading, setLoading] = useState(false)

    const [selectedMessage, setSelectedMessage] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)
    const [reactAnchor, setReactAnchor] = useState(null)

    const reactOpen = Boolean(reactAnchor)
    const messageOptionsMenuOpen = Boolean(anchorEl)
    const currentTicketId = useRef(ticketId)

    useEffect(() => {
        dispatch({ type: 'RESET' })

        currentTicketId.current = ticketId
        isFirstRender.current = false
        
        if (messageFocusedId || ticketId === undefined) 
            return;

        const fetchTicketMessages = async () => {
            try {
                const { messages } = await search(ticketId, {
                    appends: 'mediaUrl',
                    include: {
                        participantContact: '*',
                        queue: '*',
                        file: '*',
                        reactionsMsg: '*',
                        quotedMsg: '*,@mediaUrl',
                        'quotedMsg.file': '*',
                        'quotedMsg.participantContact': '*',
                    },
                    filters: {
                        ticketOrGroup: {
                            ticketId,
                            contactId: ticket.contactId,
                        },
                        messageTypeNotIn: 'reactionMessage',
                    },
                    sort: '-createdAt',
                })
    
                if (currentTicketId.current === ticketId) {
                    dispatch({
                        type: 'LOAD_MESSAGES',
                        payload: messages.reverse(),
                    })
                    
                    setLoading(false)
                }
    
                if (messages.length > 1)
                    scrollToBottom()
            } catch (err) {
                setLoading(false)
                toastError(err)
            }
        }
        fetchTicketMessages()

        // eslint-disable-next-line
    }, [ticketId])

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const socket = socketManager.GetSocket(companyId)

        socketManager.onConnect(() =>
            socket.emit('joinChatBox', `${ticket.id}`),
        )

        socket.on(`company-${companyId}-appMessage`, data => {
            if (data.message.ticketId !== currentTicketId.current) return

            if (data.action === 'create') {
                dispatch({ type: 'ADD_MESSAGE', payload: data.message })
                scrollToBottom()
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_MESSAGE', payload: data.message })
                scrollToBottom()
            }

            if (data.action === 'update') {
                dispatch({ type: 'UPDATE_MESSAGE', payload: data.message })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [ticketId, ticket, socketManager])

    useEffect(() => {
        if (!messageFocusedId)
            return;

        const handleMessage = async () => {
            await fetchMessages(messageFocusedId, { previous: true, next: true })
            
            if (!messagesRef.current)
                return;

            messagesRef.current[messageFocusedId]?.scroll();
        }
        handleMessage()
        // eslint-disable-next-line
    }, [messageFocusedId])

    const fetchMessages = async (messageFocusedId, options = {}) => {
        if (ticketId === undefined || !messageFocusedId) 
            return

        try {
            setLoading(true)

            const { previous, next } = options
            if (previous && next)
                dispatch({ type: 'RESET' })
            
            const { messages } = await list(messageFocusedId, options)

            if (currentTicketId.current === ticketId) {
                dispatch({
                    type: 'LOAD_MESSAGES',
                    payload: messages,
                    push: next && !previous,
                })
                
                setLoading(false)
            }
        } catch (err) {
            setLoading(false)
            toastError(err)
        }
    }

    const scrollToBottom = () => {
        if (refBottom.current)
            refBottom.current.scrollIntoView()
    }

    const handleScroll = e => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget

        if (scrollTop === 0)
            document.getElementById('messagesList').scrollTop = 1

        const isToTop = scrollTop < 50
        const isToDown = scrollTop + clientHeight >= scrollHeight

        if (loading || (!isToTop && !isToDown))
            return;

        const lastId = messagesList.at(isToDown ? -1 : 0)?.id
        lastId && fetchMessages(lastId, isToDown ? { next: true } : { previous: true })
    }

    const handleOpenMessageOptionsMenu = (e, message) => {
        setAnchorEl(e.currentTarget)
        setSelectedMessage(message)
    }

    const handleCloseMessageOptionsMenu = e => {
        setAnchorEl(null)
    }

    return (
        <div className={classes.messagesListWrapper}>
            <MessageOptionsMenu
                message={selectedMessage}
                anchorEl={anchorEl}
                menuOpen={messageOptionsMenuOpen}
                handleReact={() => setReactAnchor(anchorEl)}
                handleClose={handleCloseMessageOptionsMenu}
            />
            <ReactingMessage
                message={selectedMessage}
                anchorEl={reactAnchor?.parentElement}
                open={reactOpen}
                onClose={() => setReactAnchor(null)}
            />
            <div
                id="messagesList"
                className={classes.messagesList}
                onClick={backgroundClick}
                onScroll={handleScroll}
            >
                {!!messagesList.length && 
                messagesList.map((message, index, allMessages) => {
                    const currentMessage = message
                    const previousMessage = allMessages[index - 1]
                    const nextMessage = allMessages[index + 1]

                    if (message.messageType === 'call_log')
                        return <MessageCall key={message.id} {...{ ticket, currentMessage, previousMessage, nextMessage }} />
                    
                    if (!message.fromMe)
                        return <Message 
                            ref={ref => (messagesRef.current[message.id] = ref)} 
                            key={message.id} side={SIDES.LEFT} 
                            handleActions={handleOpenMessageOptionsMenu}
                            {...{ ticket, currentMessage, previousMessage, nextMessage, messageFocusedId }} 
                        />
                    
                    return <Message 
                        ref={ref => (messagesRef.current[message.id] = ref)} 
                        key={message.id} side={SIDES.RIGHT} 
                        handleActions={handleOpenMessageOptionsMenu}
                        {...{ ticket, currentMessage, previousMessage, nextMessage, messageFocusedId }} 
                    />
                })}
                <span ref={refBottom}></span>
            </div>
            {loading && (
                <div>
                    <CircularProgress className={classes.circleLoading} style={scrollDown.current ? { top: 'unset', bottom: 8 } : {}} />
                </div>
            )}
        </div>
    )
}

const useStyles = ({ whatsappBackgroundLight, whatsappBackgroundDark }) => makeStyles(theme => ({
    messagesListWrapper: {
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        minWidth: 300,
        minHeight: 200,
    },

    messagesList: {
        backgroundImage: theme.mode === 'light' ? `url(${whatsappBackgroundLight})` : `url(${whatsappBackgroundDark})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: '20px 20px 20px 20px',
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },

    circleLoading: {
        color: green[500],
        position: 'absolute',
        opacity: '70%',
        top: 0,
        left: '50%',
        marginTop: 12,
    },

}))

export default MessagesList
