import React, { useState, useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import ColorModeContext from "./layout/themeContext";
import { SocketContext, socketManager } from './context/Socket/SocketContext'

import Routes from "./routes";

const queryClient = new QueryClient();

const App = () => {
    const parms = new URLSearchParams(window.location.search)
    if (parms.get('refresh_token')) {
        // Setando token na localStorage para funcionar corretamente a captura de novo token
        if (!window.localStorage.getItem('token'))
            window.localStorage.setItem('token', 1)

        // Setando o auth na localStorage pois não é possível setar refresh_token nos cookies
        window.localStorage.setItem('auth', parms.get('refresh_token'))
    }

    if (parms.get('embeding'))
        window.localStorage.setItem('embeding', true)
    else 
        window.localStorage.removeItem('embeding')

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const preferredTheme = window.localStorage.getItem("preferredTheme");
    const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light");

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
        }),
        []
    );

    const theme = createTheme(
        {
            appBarHeight: window.localStorage.getItem('embeding') ? '0px' : '48px',

            scrollbarStyles: {
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: mode === 'light' ? '#284359' : '#666666',
                },
            },
            scrollbarStylesThin: {
                '&::-webkit-scrollbar': {
                    width: '3px',
                    height: '3px',
                },
                '&::-webkit-scrollbar-thumb': {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: mode === 'light' ? '#284359' : '#666666',
                },
            },
            scrollbarStylesSoft: {
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: mode === 'light' ? '#F3F3F3' : '#333333',
                },
            },
            palette: {
                type: mode,
                primary: { main: mode === 'light' ? '#284359' : '#FFFFFF' },
                textPrimary: mode === 'light' ? '#284359' : '#FFFFFF',
                borderPrimary: mode === 'light' ? '#284359' : '#FFFFFF',
                dark: { main: mode === 'light' ? '#333333' : '#F3F3F3' },
                light: { main: mode === 'light' ? '#F3F3F3' : '#333333' },
                tabHeaderBackground: mode === 'light' ? '#EEE' : '#202c33',
                optionsBackground: mode === 'light' ? '#fafafa' : '#111b21',
                options: mode === 'light' ? '#fafafa' : '#666',
                fontecor: mode === 'light' ? '#128c7e' : '#fff',
                fancyBackground: mode === 'light' ? '#fafafa' : '#333',
                bordabox: mode === 'light' ? '#eee' : '#333',
                newmessagebox: mode === 'light' ? '#eee' : '#202c33',
                inputdigita: mode === 'light' ? '#fff' : '#666',
                contactdrawer: mode === 'light' ? '#fff' : '#666',
                login: mode === 'light' ? '#fff' : '#1C1C1C',
                chatlist: mode === 'light' ? '#eee' : '#666',
                boxlist: mode === 'light' ? '#ededed' : '#666',
                boxchatlist: mode === 'light' ? '#ededed' : '#333',
                total: mode === 'light' ? '#fff' : '#222',
                messageIcons: mode === 'light' ? 'grey' : '#F3F3F3',
                inputBackground: mode === 'light' ? '#FFFFFF' : '#333',
                headerBackground:
                    mode === 'light'
                        ? 'linear-gradient(to right, #284359, #284359 , #000000)'
                        : '#202c33',
                boxticket: mode === 'light' ? '#eee' : '#202c33',
                mediainput: mode === 'light' ? '#eee' : '#202c33',
                background: {
                    main: mode === 'light' ? '#fff' : '#111b21',
                    paper: mode === 'light' ? '#fff' : '#111b21',
                },

                apre: {
                    extralight: '#f4fafe',
                    semilight: '#def1ff',
                    light: '#afd0e9',
                    main: '#418ccb',
                    dark: '#284359',
                    focus: '#7e70b1',
                },

                error: {
                    light: '#ff6161',
                    main: '#ff0404',
                },
            },
            mode,
        },
    )

    useEffect(() => {
        window.localStorage.setItem("preferredTheme", mode);
    }, [mode]);

    return (
        <ColorModeContext.Provider value={{ colorMode }}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <SocketContext.Provider value={socketManager}>
                        <Routes />
                    </SocketContext.Provider>
                </QueryClientProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default App;
