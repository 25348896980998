import { Button, Divider, makeStyles } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import LocationPreview from 'components/LocationPreview'
import ModalImageCors from 'components/ModalImageCors'
import VcardPreview from 'components/VcardPreview'

const useStyles = makeStyles(theme => ({
    messageMedia: {
        objectFit: 'contain',
        width: 250,
        maxHeight: 200,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },

    downloadMedia: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'inherit',
        padding: 10,
    },
}))

const RenderMessageMedia = ({message}) => {
    const classes = useStyles()
    const isSticker = JSON.parse(message.dataJson ?? '')?.message?.stickerMessage

    if (
        ['locationMessage', 'liveLocationMessage'].includes(
            message.messageType,
        ) &&
        message.body.split('|').length >= 2
    ) {
        let locationParts = message.body.split('|')
        let imageLocation = locationParts[0]
        let linkLocation = locationParts[1]

        let descriptionLocation = ''

        if (locationParts.length > 2)
            descriptionLocation = message.body.split('|')[2]

        return (
            <LocationPreview
                image={imageLocation}
                link={linkLocation}
                description={descriptionLocation}
            />
        )
    }

    if (message.messageType === 'image')
        return <ModalImageCors imageUrl={message.mediaUrl} isSticker={isSticker} />

    if (message.messageType === 'audio')
        return (
            <audio controls>
                <source src={message.mediaUrl} type="audio/ogg"></source>
            </audio>
        )

    if (message.messageType === 'video')
        return (
            <video
                className={classes.messageMedia}
                src={message.mediaUrl}
                controls
            />
        )

    if (
        ['contactMessage', 'contactsArrayMessage'].includes(message.messageType)
    )
        return <VcardPreview vcard={message.body} />

    return (
        <>
            <div className={classes.downloadMedia}>
                <Button
                    startIcon={<GetApp />}
                    color="primary"
                    variant="outlined"
                    target="_blank"
                    href={message.mediaUrl}>
                    Download
                </Button>
            </div>
            <Divider />
        </>
    )
}

export default RenderMessageMedia
