import React, { useState, useEffect, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { Grid, ListItemText, Typography, makeStyles } from '@material-ui/core'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'

import api from '../../services/api'
import ButtonWithSpinner from '../ButtonWithSpinner'
import toastError from '../../errors/toastError'
import useQueues from '../../hooks/useQueues'
import { AuthContext } from '../../context/Auth/AuthContext'

const useStyles = makeStyles(theme => ({
    maxWidth: {
        width: '100%',
    },
}))

const filterOptions = createFilterOptions({
    trim: true,
})

const TransferTicketModalCustom = ({ modalOpen, onClose, ticketid }) => {
    const history = useHistory()
    const [options, setOptions] = useState([])
    const [queues, setQueues] = useState([])
    const [allQueues, setAllQueues] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchParam, setSearchParam] = useState('')
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedQueue, setSelectedQueue] = useState('')
    const classes = useStyles()
    const { findAll: findAllQueues } = useQueues()
    const isMounted = useRef(true)
    const [whatsapps, setWhatsapps] = useState([])
    const [selectedWhatsapp, setSelectedWhatsapp] = useState('')
    const { user } = useContext(AuthContext)
    const { companyId, whatsappId } = user

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                api.get(`/whatsapp`, { companyId, session: 0 }).then(
                    ({ data }) => setWhatsapps(data),
                )
            }

            if (whatsappId !== null && whatsappId !== undefined) {
                setSelectedWhatsapp(whatsappId)
            }

            if (user.queues.length === 1) {
                setSelectedQueue(user.queues[0].id)
            }
            fetchContacts()
            setLoading(false)
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [companyId, user, whatsappId])

    useEffect(() => {
        if (isMounted.current) {
            const loadQueues = async () => {
                const list = await findAllQueues()
                setAllQueues(list)
                setQueues(list)
            }
            loadQueues()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!modalOpen) {
            setLoading(false)
            return
        }
        const delayDebounceFn = setTimeout(() => {
            setLoading(true)
            const fetchUsers = async () => {
                try {
                    const { data } = await api.get('/users/', {
                        fields: 'id,name,email,companyId,createdAt',
                        filters: {
                            smart: searchParam,
                            inactive: false,
                        },
                        include: {
                            queues: 'id,name,colod',
                            company: 'id,name',
                        },
                    })
                    setOptions(data.data)
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }

            fetchUsers()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchParam, modalOpen])

    const handleClose = () => {
        onClose()
        setSearchParam('')
        setSelectedUser(null)
    }

    const handleSaveTicket = async e => {
        e.preventDefault()
        if (!ticketid) return

        setLoading(true)
        try {
            let data = {}

            if (selectedUser) {
                data.userId = selectedUser.id
            }

            if (selectedQueue && selectedQueue !== null) {
                data.queueId = selectedQueue

                if (!selectedUser) {
                    data.status = 'pending'
                    data.userId = null
                }
            }

            if (selectedWhatsapp) {
                data.whatsappId = selectedWhatsapp
            }
            await api.put(`/tickets/${ticketid}`, data)

            history.push(`/tickets`)
        } catch (err) {
            setLoading(false)
            toastError(err)
        }
    }

    return (
        <Dialog
            open={modalOpen}
            onClose={handleClose}
            maxWidth="lg"
            scroll="paper">
            <form onSubmit={handleSaveTicket}>
                <DialogTitle id="form-dialog-title">
                    Transferir Ticket
                </DialogTitle>
                <DialogContent dividers>
                    <Autocomplete
                        style={{ width: 300, marginBottom: 20 }}
                        getOptionLabel={option => `${option.name}`}
                        onChange={(e, newValue) => {
                            setSelectedUser(newValue)
                            if (
                                newValue != null &&
                                Array.isArray(newValue.queues)
                            ) {
                                setQueues(newValue.queues)
                            } else {
                                setQueues(allQueues)
                                setSelectedQueue('')
                            }
                        }}
                        options={options}
                        filterOptions={filterOptions}
                        freeSolo
                        autoHighlight
                        noOptionsText="Nenhum usuário encontrado com esse nome"
                        loading={loading}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Digite para buscar usuários"
                                variant="outlined"
                                autoFocus
                                onChange={e => setSearchParam(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <FormControl
                        variant="outlined"
                        className={classes.maxWidth}>
                        <InputLabel>
                            Transferir para setor
                        </InputLabel>
                        <Select
                            value={selectedQueue}
                            onChange={e => setSelectedQueue(e.target.value)}
                            label="Selecione um setor"
                            placeholder="Selecione um setor">
                            {queues.map(queue => (
                                <MenuItem key={queue.id} value={queue.id}>
                                    {queue.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* CONEXAO */}
                    <Grid container spacing={2} style={{ marginTop: '15px' }}>
                        <Grid xs={12} item>
                            <Select
                                required
                                fullWidth
                                displayEmpty
                                variant="outlined"
                                value={selectedWhatsapp}
                                onChange={e => {
                                    setSelectedWhatsapp(e.target.value)
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                renderValue={() => {
                                    if (selectedWhatsapp === '') {
                                        return 'Selecione uma Conexão'
                                    }
                                    const whatsapp = whatsapps.find(
                                        w => w.id === selectedWhatsapp,
                                    )
                                    return whatsapp.name
                                }}>
                                {whatsapps?.length > 0 &&
                                    whatsapps.map((whatsapp, key) => (
                                        <MenuItem
                                            dense
                                            key={key}
                                            value={whatsapp.id}>
                                            <ListItemText
                                                primary={
                                                    <>
                                                        <Typography
                                                            component="span"
                                                            style={{
                                                                fontSize: 14,
                                                                marginLeft:
                                                                    '10px',
                                                                display:
                                                                    'inline-flex',
                                                                alignItems:
                                                                    'center',
                                                                lineHeight: '2',
                                                            }}>
                                                            {whatsapp.name}{' '}
                                                            &nbsp;{' '}
                                                            <p
                                                                className={
                                                                    whatsapp.status ===
                                                                    'CONNECTED'
                                                                        ? classes.online
                                                                        : classes.offline
                                                                }>
                                                                (
                                                                {
                                                                    whatsapp.status
                                                                }
                                                                )
                                                            </p>
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={loading}
                        variant="outlined">
                        Cancelar
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="submit"
                        color="primary"
                        loading={loading}>
                        Transferir
                    </ButtonWithSpinner>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default TransferTicketModalCustom

