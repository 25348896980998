import React, { useState, useEffect, useContext, createContext } from "react";

import { useHistory } from 'react-router-dom'

import { toast } from 'react-toastify'

import api from '../../services/api'
import toastError from '../../errors/toastError'
import { SocketContext } from '../../context/Socket/SocketContext'
import moment from 'moment'


const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const socketManager = useContext(SocketContext)
    const history = useHistory()
    const [isAuth, setIsAuth] = useState(!!localStorage.getItem('token')) // Caso já tenha o token na localStorage, o usuário é considerado logado por padrão
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState({})

    const refreshToken = async () => {
        // Se tiver um token auth na localStorage, usamos esse token como refresh_token
        const payload = {}
        if (localStorage.getItem('auth'))
            payload.jrt = localStorage.getItem('auth')

        return api.post('/auth/refresh_token', payload).then(({ data }) => {
            api.defaults.headers.Authorization = `Bearer ${data.token}`

            // Setamos o novo token e a companyId
            localStorage.setItem('token', JSON.stringify(data.token))
            localStorage.setItem('companyId', data.user?.companyId)
            setIsAuth(true)
            setUser(data.user)
            // Removido o antigo auth da localStorage
            localStorage.removeItem('auth')
        })
    }

    api.interceptors.request.use(
        config => {
            const token = localStorage.getItem('token')
            if (token) {
                config.headers['Authorization'] = `Bearer ${JSON.parse(token)}`
                setIsAuth(true)
            }
            return config
        },
        error => {
            Promise.reject(error)
        },
    )

    api.interceptors.response.use(
        response => {
            return response
        },
        async error => {
            const originalRequest = error.config
            if (error?.response?.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true

                await refreshToken()
                return api(originalRequest)
            }
            if (error?.response?.status === 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('companyId')
                api.defaults.headers.Authorization = undefined
                setIsAuth(false)
            }
            return Promise.reject(error)
        },
    )

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token)
            refreshToken()
                .catch(err => {
                    setIsAuth(false)
                    toastError(err)
                })
                .then(() => {
                    setLoading(false)
                })
        else setLoading(false)
    }, [])

    const handleLogin = async userData => {
        setLoading(true)

        try {
            const { data } = await api.post('/auth/login', userData)
            const {
                user: { companyId, id },
            } = data

            moment.locale('pt-br')
            const dueDate = data.user.company.dueDate
            const vencimento = moment(dueDate).format('DD/MM/yyyy')

            var diff = moment(dueDate).diff(moment(moment()).format())

            var before = moment(moment().format()).isBefore(dueDate)
            var days = moment.duration(diff).asDays()

            if (before === true) {
                localStorage.setItem('token', JSON.stringify(data.token))
                localStorage.setItem('companyId', companyId)
                localStorage.setItem('userId', id)
                localStorage.setItem('companyDueDate', vencimento)
                api.defaults.headers.Authorization = `Bearer ${data.token}`
                setUser(data.user)
                setIsAuth(true)
                toast.success('Login efetuado com sucesso!')
                if (Math.round(days) < 5) {
                    toast.warn(
                        `Sua assinatura vence em ${Math.round(days)} ${
                            Math.round(days) === 1 ? 'dia' : 'dias'
                        } `,
                    )
                }
                history.push('/tickets')
                setLoading(false)
            } else {
                toastError(
                    `Opss! Sua assinatura venceu ${vencimento}. Entre em contato com o Suporte para mais informações! `,
                )
                setLoading(false)
            }

            //quebra linha
        } catch (err) {
            toastError(err)
            setLoading(false)
        }
    }

    const handleLogout = async () => {
        setLoading(true)
        try {
            await api.delete('/auth/logout')
        } catch (err) {
            toastError(err)
        }

        setIsAuth(false)
        setUser({})
        localStorage.removeItem('token')
        localStorage.removeItem('companyId')
        localStorage.removeItem('userId')
        api.defaults.headers.Authorization = undefined
        setLoading(false)
        history.push('/login')
    }

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        if (companyId) {
            const socket = socketManager.GetSocket(companyId)

            socket.on(`company-${companyId}-user`, data => {
                if (data.action === 'update' && data.user.id === user.id) {
                    if (data.user.inactive) return handleLogout()
                    setUser(data.user)
                }
            })

            socket.on(`company-${companyId}-role`, data => {
                if (data.action === 'update' && data.role.id === user.roleId)
                    setUser({ ...user, role: data.role })
            })

            return () => {
                socket.disconnect()
            }
        }

        // eslint-disable-next-line
    }, [user, socketManager])

	return (
		<AuthContext.Provider
			value={{ loading, user, isAuth, handleLogin, handleLogout }}
		>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
