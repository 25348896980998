import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
    makeStyles,
    createTheme,
    ThemeProvider,
} from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { MoreVert, Replay } from '@material-ui/icons'

import api from '../../services/api'
import TicketOptionsMenu from '../TicketOptionsMenu'
import ButtonWithSpinner from '../ButtonWithSpinner'
import toastError from '../../errors/toastError'
import { AuthContext } from '../../context/Auth/AuthContext'
import { TicketsContext } from '../../context/Tickets/TicketsContext'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import UndoRoundedIcon from '@material-ui/icons/UndoRounded'
import SearchIcon from '@material-ui/icons/Search'
import Tooltip from '@material-ui/core/Tooltip'
import { green } from '@material-ui/core/colors'
import CloseTicketModal from 'components/CloseTicketModal'
import SearchMessage from 'components/SearchMessage'
import ConfirmationModal from 'components/ConfirmationModal'

const useStyles = makeStyles(theme => ({
    actionButtons: {
        marginRight: 6,
        flex: 'none',
        alignSelf: 'center',
        marginLeft: 'auto',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}))

const TicketActionButtonsCustom = ({ ticket, onSearch, noActions = false }) => {
    const classes = useStyles()
    const history = useHistory()
    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openFinish, setOpenFinish] = useState(false)
    const [openSearch, setOpenSearch] = useState(false)
    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const ticketOptionsMenuOpen = Boolean(anchorEl)
    const { user } = useContext(AuthContext)
    const { setCurrentTicket } = useContext(TicketsContext)

    const customTheme = createTheme({
        palette: {
            primary: green,
        },
    })

    const handleOpenTicketOptionsMenu = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleCloseTicketOptionsMenu = e => {
        setAnchorEl(null)
    }

    const handleUpdateTicketStatus = async (e, status, userId) => {
        if (status === 'closed') return setOpenFinish(true)

        setLoading(true)
        try {
            await api.put(`/tickets/${ticket.id}`, {
                status: status,
                userId: userId || null,
                flowId: null,
                step: null,
            })

            setLoading(false)
            if (status === 'open') {
                setCurrentTicket({ ...ticket, code: '#open' })
            } else {
                setCurrentTicket({ id: null, code: null })
                history.push('/tickets')
            }
        } catch (err) {
            setLoading(false)
            toastError(err)
        }
    }

    return (
        <div className={classes.actionButtons}>
            <CloseTicketModal
                ticket={ticket}
                open={openFinish}
                onClose={() => setOpenFinish(false)}
            />
            <SearchMessage
                ticket={ticket}
                open={openSearch}
                onClick={onSearch}
                onClose={() => setOpenSearch(false)}
            />
            <ConfirmationModal
                title={`Retornar o atendimento do contato ${ticket.contact.name} #${ticket.id}?`}
                open={confirmationOpen}
                onClose={setConfirmationOpen}
                onConfirm={e => handleUpdateTicketStatus(e, 'pending', null)}>
                O atendimento voltará para o status aguardando.
            </ConfirmationModal>

            {!noActions && ticket.status === 'closed' && (
                <ButtonWithSpinner
                    loading={loading}
                    startIcon={<Replay />}
                    size="small"
                    onClick={e =>
                        handleUpdateTicketStatus(e, 'open', user?.id)
                    }>
                    Reabrir
                </ButtonWithSpinner>
            )}
            {ticket.status === 'open' && (
                <ThemeProvider theme={customTheme}>
                    {!noActions && (
                        <>
                            <Tooltip title="Retornar">
                                <IconButton
                                    onClick={e => setConfirmationOpen(true)}>
                                    <UndoRoundedIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Finalizar">
                                <IconButton
                                    onClick={e =>
                                        handleUpdateTicketStatus(
                                            e,
                                            'closed',
                                            user?.id,
                                        )
                                    }
                                    color="primary">
                                    <CheckCircleIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}

                    <Tooltip title="Buscar">
                        <IconButton onClick={() => setOpenSearch(true)}>
                            <SearchIcon />
                        </IconButton>
                    </Tooltip>
                    
                    {!noActions && (
                        <>
                            <IconButton onClick={handleOpenTicketOptionsMenu}>
                                <MoreVert />
                            </IconButton>
                            <TicketOptionsMenu
                                ticket={ticket}
                                anchorEl={anchorEl}
                                menuOpen={ticketOptionsMenuOpen}
                                handleClose={handleCloseTicketOptionsMenu}
                            />
                        </>
                    )}
                </ThemeProvider>
            )}
            {['pending', 'bot'].includes(ticket.status) && (
                <ButtonWithSpinner
                    loading={loading}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={e =>
                        handleUpdateTicketStatus(e, 'open', user?.id)
                    }>
                    Aceitar
                </ButtonWithSpinner>
            )}
        </div>
    )
}

export default TicketActionButtonsCustom
