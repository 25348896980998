import React, { useState, useEffect, useReducer, useContext } from 'react'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import Title from '../../components/Title'

import api from '../../services/api'
import TableRowSkeleton from '../../components/TableRowSkeleton'
import QuickMessageDialog from '../../components/QuickMessageDialog'
import ConfirmationModal from '../../components/ConfirmationModal'
import toastError from '../../errors/toastError'
import { Grid } from '@material-ui/core'
import { isArray } from 'lodash'
import { SocketContext } from '../../context/Socket/SocketContext'
import { AuthContext } from '../../context/Auth/AuthContext'
import useCan from 'hooks/useCan'
import { AddCircleOutline } from '@material-ui/icons'

const reducer = (state, action) => {
    if (action.type === 'LOAD_QUICKMESSAGES') {
        const quickmessages = action.payload
        const newQuickmessages = []

        if (isArray(quickmessages)) {
            quickmessages.forEach(quickemessage => {
                const quickemessageIndex = state.findIndex(
                    u => u.id === quickemessage.id,
                )
                if (quickemessageIndex !== -1) {
                    state[quickemessageIndex] = quickemessage
                } else {
                    newQuickmessages.push(quickemessage)
                }
            })
        }

        return [...state, ...newQuickmessages]
    }

    if (action.type === 'UPDATE_QUICKMESSAGES') {
        const quickemessage = action.payload
        const quickemessageIndex = state.findIndex(
            u => u.id === quickemessage.id,
        )

        if (quickemessageIndex !== -1) {
            state[quickemessageIndex] = quickemessage
            return [...state]
        } else {
            return [quickemessage, ...state]
        }
    }

    if (action.type === 'DELETE_QUICKMESSAGE') {
        const quickemessageId = action.payload

        const quickemessageIndex = state.findIndex(
            u => u.id === quickemessageId,
        )
        if (quickemessageIndex !== -1) {
            state.splice(quickemessageIndex, 1)
        }
        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },

    tableCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '16vw',
    }
}))

const Quickemessages = () => {
    const classes = useStyles()
    const socketManager = useContext(SocketContext)
    const { canOrReturn } = useCan()

    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const [selectedQuickemessage, setSelectedQuickemessage] = useState(null)
    const [deletingQuickemessage, setDeletingQuickemessage] = useState(null)
    const [quickemessageModalOpen, setQuickMessageDialogOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [searchParam, setSearchParam] = useState('')
    const [quickemessages, dispatch] = useReducer(reducer, [])
    const { user } = useContext(AuthContext)

    useEffect(() => {
        dispatch({ type: 'RESET' })
        setPageNumber(1)
    }, [searchParam])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            fetchQuickemessages()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber])

    useEffect(() => {
        const companyId = user.companyId
        const socket = socketManager.GetSocket(companyId)

        socket.on(`company${companyId}-quickemessage`, data => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_QUICKMESSAGES', payload: data.record })
            }
            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_QUICKMESSAGE', payload: +data.id })
            }
        })
        return () => {
            socket.disconnect()
        }
    }, [user, socketManager])

    const fetchQuickemessages = async () => {
        try {
            //const searchParam = ({ companyId, userId: user.id });
            const { data } = await api.get('/quick-messages', {
                searchParam,
                pageNumber,
                userId: user.id,
            })

            dispatch({ type: 'LOAD_QUICKMESSAGES', payload: data.records })
            setHasMore(data.hasMore)
            setLoading(false)
        } catch (err) {
            toastError(err)
        }
    }

    const handleOpenQuickMessageDialog = () => {
        setSelectedQuickemessage(null)
        setQuickMessageDialogOpen(true)
    }

    const handleCloseQuickMessageDialog = () => {
        setSelectedQuickemessage(null)
        setQuickMessageDialogOpen(false)
        //window.location.reload();
        fetchQuickemessages()
    }

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase())
    }

    const handleEditQuickemessage = quickemessage => {
        setSelectedQuickemessage(quickemessage)
        setQuickMessageDialogOpen(true)
    }

    const handleDeleteQuickemessage = async quickemessageId => {
        try {
            await api.delete(`/quick-messages/${quickemessageId}`)
            toast.success('Atalho removido com sucesso!')
        } catch (err) {
            toastError(err)
        }
        setDeletingQuickemessage(null)
        setSearchParam('')
        setPageNumber(1)
        fetchQuickemessages()
        dispatch({ type: 'RESET' })
    }

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1)
    }

    const handleScroll = e => {
        if (!hasMore || loading) return
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore()
        }
    }

    if (
        !canOrReturn(
            ['quickMessage:page', 'quickMessage:delete', 'quickMessage:create', 'quickMessage:update'],
            '/tickets',
        )
    )
        return null

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingQuickemessage &&
                    `Excluir ${
                        deletingQuickemessage.shortcode
                    }?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() =>
                    handleDeleteQuickemessage(deletingQuickemessage.id)
                }>
                Esta ação é irreversível! Deseja prosseguir?
            </ConfirmationModal>
            <QuickMessageDialog
                resetPagination={() => {
                    setPageNumber(1)
                    fetchQuickemessages()
                }}
                open={quickemessageModalOpen}
                onClose={handleCloseQuickMessageDialog}
                aria-labelledby="form-dialog-title"
                quickemessageId={
                    selectedQuickemessage && selectedQuickemessage.id
                }
            />
            <MainHeader>
                <Grid style={{ width: '99.6%' }} container>
                    <Grid xs={12} sm={6} md={8} item>
                        <Title>Respostas Rápidas</Title>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item>
                        <Grid spacing={2} container>
                            <Grid xs={6} sm={6} item>
                                <TextField
                                    fullWidth
                                    placeholder={'Pesquisar...'}
                                    type="search"
                                    value={searchParam}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon
                                                    style={{ color: 'gray' }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid xs={6} sm={6} item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={handleOpenQuickMessageDialog}
                                    color="primary"
                                >
                                    <Grid container justifyContent='center' alignItems="center" style={{ gap: 4 }}>
                                        <AddCircleOutline />
                                        Adicionar
                                    </Grid>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                Atalho
                            </TableCell>
                            <TableCell align="center">
                                Mensagem
                            </TableCell>
                            <TableCell align="center">
                                Nome do Arquivo
                            </TableCell>
                            <TableCell align="center">
                                Ações
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {quickemessages.map(quickemessage => (
                                <TableRow key={quickemessage.id}>
                                    <TableCell align="center">
                                        {quickemessage.shortcode}
                                    </TableCell>

                                    <TableCell align="center" className={classes.tableCell}>
                                        {quickemessage.message}
                                    </TableCell>

                                    <TableCell align="center">
                                        {quickemessage.mediaPath ?? 'Sem anexo'}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleEditQuickemessage(
                                                    quickemessage,
                                                )
                                            }>
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={e => {
                                                setConfirmModalOpen(true)
                                                setDeletingQuickemessage(
                                                    quickemessage,
                                                )
                                            }}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={5} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Quickemessages
