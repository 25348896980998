import React, { useState, useCallback, useContext } from 'react'
import { toast } from 'react-toastify'
import { format, parseISO } from 'date-fns'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import {
    Button,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Table,
    TableHead,
    Paper,
    Tooltip,
    Typography,
    CircularProgress,
    Grid,
} from '@material-ui/core'
import {
    Edit,
    CheckCircle,
    SignalCellularConnectedNoInternet2Bar,
    SignalCellularConnectedNoInternet0Bar,
    SignalCellular4Bar,
    CropFree,
    DeleteOutline,
    AddCircleOutline,
} from '@material-ui/icons'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper'
import Title from '../../components/Title'
import TableRowSkeleton from '../../components/TableRowSkeleton'

import api from '../../services/api'
import WhatsAppModal from '../../components/WhatsAppModal'
import ConfirmationModal from '../../components/ConfirmationModal'
import QrcodeModal from '../../components/QrcodeModal'
import { WhatsAppsContext } from '../../context/WhatsApp/WhatsAppsContext'
import toastError from '../../errors/toastError'

import { Can } from '../../components/Can'
import useCan from 'hooks/useCan'

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
        maxWidth: 450,
    },
    tooltipPopper: {
        textAlign: 'center',
    },
    buttonProgress: {
        color: green[500],
    },
}))

const CustomToolTip = ({ title, content, children }) => {
    const classes = useStyles()

    return (
        <Tooltip
            arrow
            classes={{
                tooltip: classes.tooltip,
                popper: classes.tooltipPopper,
            }}
            title={
                <React.Fragment>
                    <Typography gutterBottom color="inherit">
                        {title}
                    </Typography>
                    {content && <Typography>{content}</Typography>}
                </React.Fragment>
            }>
            {children}
        </Tooltip>
    )
}

const Connections = () => {
    const classes = useStyles()
    const { canOrReturn } = useCan()

    const { whatsApps, loading } = useContext(WhatsAppsContext)
    const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false)
    const [qrModalOpen, setQrModalOpen] = useState(false)
    const [selectedWhatsApp, setSelectedWhatsApp] = useState(null)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const confirmationModalInitialState = {
        action: '',
        title: '',
        message: '',
        whatsAppId: '',
        open: false,
    }
    const [confirmModalInfo, setConfirmModalInfo] = useState(
        confirmationModalInitialState,
    )

    const handleStartWhatsAppSession = async whatsAppId => {
        try {
            await api.post(`/whatsappsession/${whatsAppId}`)
        } catch (err) {
            toastError(err)
        }
    }

    const handleRequestNewQrCode = async whatsAppId => {
        try {
            await api.put(`/whatsappsession/${whatsAppId}`)
        } catch (err) {
            toastError(err)
        }
    }

    const handleOpenWhatsAppModal = () => {
        setSelectedWhatsApp(null)
        setWhatsAppModalOpen(true)
    }

    const handleCloseWhatsAppModal = useCallback(() => {
        setWhatsAppModalOpen(false)
        setSelectedWhatsApp(null)
    }, [setSelectedWhatsApp, setWhatsAppModalOpen])

    const handleOpenQrModal = whatsApp => {
        setSelectedWhatsApp(whatsApp)
        setQrModalOpen(true)
    }

    const handleCloseQrModal = useCallback(() => {
        setSelectedWhatsApp(null)
        setQrModalOpen(false)
    }, [setQrModalOpen, setSelectedWhatsApp])

    const handleEditWhatsApp = whatsApp => {
        setSelectedWhatsApp(whatsApp)
        setWhatsAppModalOpen(true)
    }

    const handleOpenConfirmationModal = (action, whatsAppId) => {
        if (action === 'disconnect') {
            setConfirmModalInfo({
                action: action,
                title: 'Desconectar',
                message: 'Tem certeza? Você precisará ler o QR Code novamente.',
                whatsAppId: whatsAppId,
            })
        }

        if (action === 'delete') {
            setConfirmModalInfo({
                action: action,
                title: 'Deletar',
                message: 'Você tem certeza? Essa ação não pode ser revertida.',
                whatsAppId: whatsAppId,
            })
        }
        setConfirmModalOpen(true)
    }

    const handleSubmitConfirmationModal = async () => {
        if (confirmModalInfo.action === 'disconnect') {
            try {
                await api.delete(
                    `/whatsappsession/${confirmModalInfo.whatsAppId}`,
                )
            } catch (err) {
                toastError(err)
            }
        }

        if (confirmModalInfo.action === 'delete') {
            try {
                await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`)
                toast.success('Conexão com o WhatsApp excluída com sucesso!')
            } catch (err) {
                toastError(err)
            }
        }

        setConfirmModalInfo(confirmationModalInitialState)
    }

    const renderActionButtons = whatsApp => {
        return (
            <>
                {whatsApp.status === 'qrcode' && (
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenQrModal(whatsApp)}>
                        QR CODE
                    </Button>
                )}
                {whatsApp.status === 'DISCONNECTED' && (
                    <>
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                handleStartWhatsAppSession(whatsApp.id)
                            }>
                            Tentar novamente
                        </Button>{' '}
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleRequestNewQrCode(whatsApp.id)}>
                            Novo QR CODE
                        </Button>
                    </>
                )}
                {(whatsApp.status === 'CONNECTED' ||
                    whatsApp.status === 'PAIRING' ||
                    whatsApp.status === 'TIMEOUT') && (
                    <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            handleOpenConfirmationModal(
                                'disconnect',
                                whatsApp.id,
                            )
                        }}>
                        Desconectar
                    </Button>
                )}
                {whatsApp.status === 'OPENING' && (
                    <Button
                        size="small"
                        variant="outlined"
                        disabled
                        color="default">
                        Conectando
                    </Button>
                )}
            </>
        )
    }

    const renderStatusToolTips = whatsApp => {
        return (
            <div className={classes.customTableCell}>
                {whatsApp.status === 'DISCONNECTED' && (
                    <CustomToolTip
                        title="Falha ao iniciar sessão do WhatsApp"
                        content="Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code">
                        <SignalCellularConnectedNoInternet0Bar color="secondary" />
                    </CustomToolTip>
                )}
                {whatsApp.status === 'OPENING' && (
                    <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                    />
                )}
                {whatsApp.status === 'qrcode' && (
                    <CustomToolTip
                        title="Esperando leitura do QR Code"
                        content="Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão">
                        <CropFree />
                    </CustomToolTip>
                )}
                {whatsApp.status === 'CONNECTED' && (
                    <CustomToolTip title="Conexão estabelecida!">
                        <SignalCellular4Bar style={{ color: green[500] }} />
                    </CustomToolTip>
                )}
                {(whatsApp.status === 'TIMEOUT' ||
                    whatsApp.status === 'PAIRING') && (
                    <CustomToolTip
                        title="A conexão com o celular foi perdida"
                        content="Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code">
                        <SignalCellularConnectedNoInternet2Bar color="secondary" />
                    </CustomToolTip>
                )}
            </div>
        )
    }

    if (
        !canOrReturn([
            'connection:page',
            'connection:create',
            'connection:update',
            'connection:delete',
        ])
    )
        return null

    return (
        <MainContainer>
            <ConfirmationModal
                title={confirmModalInfo.title}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={handleSubmitConfirmationModal}>
                {confirmModalInfo.message}
            </ConfirmationModal>
            <QrcodeModal
                open={qrModalOpen}
                onClose={handleCloseQrModal}
                whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
            />
            <WhatsAppModal
                open={whatsAppModalOpen}
                onClose={handleCloseWhatsAppModal}
                whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
            />
            <MainHeader>
                <Title>Conexões</Title>
                <MainHeaderButtonsWrapper>
                    <Can perform="connections:create">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenWhatsAppModal}>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                style={{ gap: 4 }}>
                                <AddCircleOutline />
                                Adicionar WhatsApp
                            </Grid>
                        </Button>
                    </Can>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">Número</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <Can perform="connections:actions">
                                <TableCell align="center">Sessão</TableCell>
                            </Can>
                            <TableCell align="center">
                                Última Atualização
                            </TableCell>
                            <TableCell align="center">Padrão</TableCell>
                            <Can perform="connections:update">
                                <TableCell align="center">Ações</TableCell>
                            </Can>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowSkeleton />
                        ) : (
                            <>
                                {whatsApps?.length > 0 &&
                                    whatsApps.map(whatsApp => (
                                        <TableRow key={whatsApp.id}>
                                            <TableCell align="center">
                                                {whatsApp.name}
                                            </TableCell>
                                            <TableCell align="center">
                                                {whatsApp.phone}
                                            </TableCell>
                                            <TableCell align="center">
                                                {renderStatusToolTips(whatsApp)}
                                            </TableCell>
                                            <Can perform="connections:actions">
                                                <TableCell align="center">
                                                    {renderActionButtons(
                                                        whatsApp,
                                                    )}
                                                </TableCell>
                                            </Can>
                                            <TableCell align="center">
                                                {format(
                                                    parseISO(
                                                        whatsApp.updatedAt,
                                                    ),
                                                    'dd/MM/yy HH:mm',
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {whatsApp.isDefault && (
                                                    <div
                                                        className={
                                                            classes.customTableCell
                                                        }>
                                                        <CheckCircle
                                                            style={{
                                                                color: green[500],
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </TableCell>

                                            <TableCell align="center">
                                                <Can perform="connections:update">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            handleEditWhatsApp(
                                                                whatsApp,
                                                            )
                                                        }>
                                                        <Edit />
                                                    </IconButton>
                                                </Can>

                                                <Can perform="connections:delete">
                                                    <IconButton
                                                        size="small"
                                                        onClick={e => {
                                                            handleOpenConfirmationModal(
                                                                'delete',
                                                                whatsApp.id,
                                                            )
                                                        }}>
                                                        <DeleteOutline />
                                                    </IconButton>
                                                </Can>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Connections
